/* Ion.RangeSlider, Simple Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// © guybowden, 2014        https://github.com/guybowden
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs {
  height: 55px;
}
.irs-with-grid {
  height: 70px;
  margin: 0 10px 0;
}
.irs-line {
  height: 2px; top: 51px;
  background-color: $range-input-line;
  border-radius: 16px;
  -moz-border-radius: 16px;
}
    .irs-line-left {
        height: 8px;
    }
    .irs-line-mid {
        height: 8px;
    }
    .irs-line-right {
        height: 8px;
    }
.irs-bar {
  height: 2px; top: 51px;
  background-color: #5898e4;
}
  .irs-bar-edge {
    height: 10px; top: 51px;
    width: 14px;
    border: 1px solid #428bca;
    border-right: 0;
    background: #428bca;
    background: linear-gradient(to top, rgba(66,139,202,1) 0%,rgba(127,195,232,1) 100%); /* W3C */
    border-radius: 16px 0 0 16px;
    -moz-border-radius: 16px 0 0 16px;
  }

.irs-shadow {
  height: 2px; top: 56px;
  background: #000;
  opacity: 0.3;
  border-radius: 5px;
  -moz-border-radius: 5px;
}
.lt-ie9 .irs-shadow {
  filter: alpha(opacity=30);
}

.irs-slider {
  top: 37px;
  width: 27px; height: 27px;
  background: #fff;
  border-radius: 27px;
  -moz-border-radius: 27px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.irs-slider.state_hover, .irs-slider:hover {
  background: #FFF;
}

.irs-min, .irs-max {
  @include breakpoint($tablet-desktop){
    display: none;
  }
  color: $menu;
  text-shadow: none;
  top: 0;
  font-weight: 500;
  line-height: 16px;
  width: 30px;
  visibility: visible!important;
  span{
    &:first-child{
      display: none;
    }
    font-size: em(12);
    font-weight: 300;
  }
}
.irs-min{
  left: 5%!important;
  padding-left: 5px;
  span{
    margin-left: -5px;
  }
}
.irs-max{
  right: 5%!important;
  text-align: right;
  padding-right: 4px;
  span{
    margin-right: -4px;
  }
}
.lt-ie9 .irs-min, .lt-ie9 .irs-max {
}

.irs-from, .irs-to, .irs-single {
  @include breakpoint($tablet-desktop){
    display: none;
  }
  visibility: visible !important;
  display:none;
  top: 6px;
  @include triangle-down($text);
  color: #fff;
  font-size: em(12);
  text-shadow: none;
  padding: 6px 9px;
  background: $text;
  border-radius: 6px;
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.25);
}
.irs-from.min{
  left: 0 !important;
  &::after{
    left: 15px;
  }
}
.irs-to.max{
  left: auto !important;
  right: 0 !important;
  &::after{
    left: auto;
    right: 15px;
    margin-right: -5px;
    margin-left: 0;
  }
}
.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
  background: #999;
}

.irs-grid {
  height: 27px;
  bottom: auto;
  top: 0;
  @include breakpoint($mobile){
    display: none !important;
  }
}
.irs-grid-pol {
  display: none;
}
.irs-grid-pol.small {
  display: none;
}

.irs-grid-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: $menu;
  height: 23px;
  vertical-align: top;
  line-height: 16px;
  text-align: left;
  span{
    font-size: em(12);
    font-weight: 300;
  }
}

.irs-disabled {
}


