.fb-invite{
  text-align: center;
  section{
    width: 100%;
    max-width:640px;
    margin: 0 auto;
    &.person{
      margin: 38px auto 25px;
      p.small-text{
        padding: 0;
      }
      h2{
        margin-top: 24px;
      }
    }
    &.child{
      font-family: 'engine', serif;
      font-weight: 400;
      color: $black;
      background-color: $white;
      border-radius: 8px;
      margin-bottom: 187px;
      .avatar{
        margin: -38px 0 2px;
        display: inline-block;
        position: relative;
      }
      .btn{

        margin: 28px 0 58px;
      }
      .bg{
        background-color: $black;
        position: relative;
        z-index: 0;
        border-radius: 8px 8px 0 0 ;
        img{
          opacity:0.75;
          display: block;
          border-radius: 8px 8px 0 0 ;
        }
      }

    }

  }
}
