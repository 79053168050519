.badge-popup {
  max-width: 640px;
  padding-top: 15px;
  min-width: 400px;
  @include breakpoint($tablet-desktop) {
    min-width: 640px;
  }
  h3 {
    @include engine;
    font-size: em(32);
    line-height: 40px;
    margin: 17px 0;
  }
  img {
    width: auto;
    display: inline-block;
    height: 318px;
    margin: 7px 0 16px;
  }
  .badge-description {
    line-height: 22px;
    margin: 0 20px;
    text-align: left;
    font-weight: 400;
    margin-bottom: 17px;
  }
  .necessary-tasks {
    margin-top: 30px;
    .necessary-task {
      padding: 16px 20px;
      box-sizing: border-box;
      display: block;
      width: 100%;
      border-top: 1px solid $light-gray;
      text-align: left;
      .icon {
        display: inline-block;
        width: 64px;
        height: 64px;
        border-radius: 32px;
        float: left;
        margin-right: 28px;
        margin-top: -10px;
        &.locked{
          @include inline-img($lock, 36px, 36px, center, center);
          background-color: $default-task-bg;
        }
        img {
          width: 32px;
          height: 32px;
          margin: 16px;
        }
      }
      .task-name {
        font-weight: 500;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        font-size: 16px;
        color: #333;
        font-weight: 500;
        margin: 10px 40px 0 0;
        @include lightening(59px);
        &:hover {
          color: $red-hover;
        }
      }
      .task-description {
        color: $light-text;
        font-weight: 300;
        margin: 4px 0 18px;
        display: block;
      }
      .favorite-star {
        float: right;
        margin-top: 10px;
      }
    }
  }
  .footer {
    border-radius: 0 0 12px 12px;
    padding: 24px 0 22px;
    background-color: $popup-footer-bg;

  }
}
.favorite-star{
  display: block;
  width: 24px;
  height: 24px;
  @include inline-img($star-empty);
  &.active{
    @include inline-img($star-full);
  }
}


