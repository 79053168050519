$width: 100px;

body {
  .loader {
    display: none;
    position: fixed;
    width: $width;
    height: $width;
    left: 50%;

    top: 50%;
    margin-top: -$width/2;
    margin-left: -$width/2;
    z-index: 102;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: $width;
    position: relative;
    width: $width;
  }
  .tasks-loader.loader {
    position: inherit !important;
    height: auto !important;
    margin: auto !important;
  }
  .tasks-loader .circular {
    height: $width/2;
    width: $width/2;
  }
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  0%, 100% {
    stroke: $omfg-blue;
  }
}
