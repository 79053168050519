.confirm-invite {
  padding: 20px;
  @include breakpoint($tablet-desktop) {
    min-width: 600px;
  }
  h2 {
    @include engine;
    font-size: em(32);
    margin: 12px 0 8px;
    line-height: 1.25;
  }
  .send-code-form {
    .error-text {
      color: $another-red;
      font-size: em(13);
      max-width: 360px;
      margin: 0 auto;
      letter-spacing: 0.4px;
      line-height: normal;
      visibility: hidden;
      min-height: 30px;
    }
    .code{
      display: block;
      width: 330px;
      height: 53px;
      margin: 7px auto 0;
      border: none;
      border-bottom: 1px $btn-gray solid;
      padding: 7px 50px 7px 72px;
      box-sizing: border-box;
      font-size: em(48);
      font-weight: bold;
      letter-spacing: 16px;
      &:focus{
        outline: none;
      }
      &.inputing {
        padding-left: 67px;
        font-size: em(32);
      }
    }
    .send-code {
      @include bigGreenButton;
      @include engine;
      &[disabled="disabled"] {
        @include bigDisabledButton;
      }
      margin: 24px 0 4px;
    }

    &.error {
      .error-text {
        visibility: visible;
      }
      .code {
        border-color: $error-border-red;
      }
    }
  }
}
.accept-child,
.accept-relationship {
  min-width: 638px;
  .relationship-header {
    margin:-1px -1px 0 -1px;
    height: 363px;
    color: $white;
    line-height: 1.2;
    padding: 90px 20px 20px;
    box-sizing: border-box;
    .avatar {
      @include avatar(152px);
      margin: 0 auto;
    }
    h2 {
      margin-top: 16px;
    }
  }
  .relationship-form {
    @include helvetica;
    line-height: 1.45;
    margin-top: 24px;
    font-size: em(18);
    .style-select{
      display:block;
      width: 372px;
      text-align: left;
    }
    input[type="submit"] {
      @include bigGreenButton;
      @include engine;
      display: block;
      width: 290px;
      box-sizing: border-box;
      margin: 48px auto 10px;
    }
    input[type="reset"] {
      @include bigBlueBorderButton;
      @include engine;
      display: block;
      width: 290px;
      box-sizing: border-box;
      margin: 10px auto;
    }
  }
}

.accept-child {
  padding-bottom: 23px;
  .relationship-header {
    @include background-image-retina($path + 'invite_bg', 'jpg', 640px, 363px);
  }
  .relationship-form {
    label {
      padding-right: 40px;
    }
  }
}

.accept-relationship {
  padding-bottom: 38px;
  .relationship-header {
    @include background-image-retina($path + 'invite_accept_bg', 'jpg', 640px, 363px);
  }
  .relationship-form {
    label {
      padding-right: 18px;
    }
  }
}
