@mixin profileContent{
  @include sectionContent;
  padding-bottom: 40px;
  position: relative;
  p{
    padding-bottom: 16px;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.profile{
  font-weight: 100;
  background: center -100px no-repeat;
  @include background-image-retina($path + 'bg', 'png', 1920px, 818px);
}

/*first section - baby info*/
.child-info{
  @include human-info(child);
  .child-text{
    .label{
      margin: 0 0px;
      vertical-align: middle;
      display: block;
      float: left;
      margin: 16px 10px 40px 0;
      position: relative;
      cursor: default;
      .tooltip{
        white-space: nowrap;
        position: absolute;
        font-size: 18px;
        left: 50%;
        top: 40px;
        background: $white;
        font-weight: 300;
        padding: 10px 22px;
        transform: translateX(-50%);
        @include triangle-up($white);
        border-radius: 8px;
        box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: opacity 125ms linear;
      }
      &:hover .tooltip{
        opacity: 1;
      }
    }
  }
}


/*popup with invite code*/
.invite-card{
  max-width: 610px;
  padding: 20px 15px 40px;
  h4{
    font-family: 'engine';
    font-size: em(32);
  }
  h1{
    font-size: em(72);
    letter-spacing: 20px;
    font-weight: 400;
    margin-top: 50px;
    a{
      width: 68px;
      height: 68px;
      vertical-align: top;
      display: inline-block;
      border-radius: 36px;
      border: solid 2px $btn-gray;
      &.invite-reload{
        @include inline-img($refresh, $posLeft: center, $posTop: center);
        margin-right: 20px;
        &:hover{
          @include inline-img($refresh-hover, $posLeft: center, $posTop: center);
          border-color: $btn-gray-hover;
        }
      }
      &.invite-copy{
        @include inline-img($copy, $posLeft: center, $posTop: center);
        background-color: $btn-gray;
        margin-left: 15px;
        &:hover{
          background-color: $btn-gray-hover;
          border-color: $btn-gray-hover;
        }
      }

    }

  }
  .links{
    margin-top: 0;
    margin-bottom: 4px;
  }
  p{
    font-size: em(14);
    line-height: 1.6;
  }
  p:last-child{
    margin-bottom: 25px;
  }
  .invite-accept{
      display: block;
      width: 230px;
      margin: 10px auto 20px;
      font-weight: 500;
      line-height: 1.5;
      padding-right: 32px;
      a{
        @include inline-img($dotted-line, $posTop: bottom, $repeat: repeat-x);
        text-decoration: none;
        padding-bottom: 5px;
        margin-left: 10px;
      }
    .checkbox{
      display: inline-block;
      vertical-align: top;
    }
  }

}

/*second section with relatives*/
.metrics{
  @include profileContent;
  h3{
    @include engine;
    font-size: em(48);
    line-height: 48px;
  }
  .charts{
    float: left;
    width: 100%;
    .metric-title{
      display: inline-flex;
      width: 100%;
      h3{
        width: 50%;
        float: left;
      }
    }
  }
  .chart{
    width: 50%;
    float: left;
    margin-top: 30px;
    .highcharts-container{
      margin-bottom: 70px;
    }
  }
  .relative{
    display:block;
    float: left;
    width: 152px;
    vertical-align: top;
    margin: 24px 25px 0 0 ;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    &:hover{
      color: $blue-border;
    }
    a{
      text-decoration: none;
      line-height:22px;
      &:hover{
        color: $blue-border;
      }
    }
    &:nth-child(5n){
      margin-right: 0;
    }
    &.add{
      @include dotted-add-circle;
    }
    &.current{
      transition: opacity 125ms linear;
      font-weight: bold;
      &:hover{
        color: $text;
      }
    }
    & .avatar{
      margin-bottom: 16px;
    }
    &.common{
      position: relative;
      .close{
        @include close-btn;
        margin-top: 10px;
        float: right;
      }
    }


  }
  .relatives{
    min-width:860px;
    float: left;
    margin-bottom: 45px;
    .popup-menu{
      display: none;
      color: $text;
      position: absolute;
      left: -40px;
      top: 142px;
      padding: 20px;
      background-color: $white;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      font-weight: 400;
      z-index: 5;
      padding: 10px 16px 14px;
      text-align: left;
      line-height: 18px;
      @include triangle-up($white);
      &::before {
        content: '';
        position: absolute;
        left: 0; top: -1px;
        display: block;
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
      }
      .close-popup{
        display: block;
        margin: 13px 0 0 8px;
        &:hover{
          color: $red-hover;
        }
      }
      .jq-selectbox{
        width: 200px;

      }
    }
    .common .avatar, .common{
      transition: all 125ms linear;
    }
    &.edit{

        .current, .add {
          opacity: .2;
          cursor: default;

        }
        .add:hover{
          color: $text;
          @include inline-img($add, 30px, 30px, 60px, 62px);
          svg{
            stroke: $avatar-border;
          }
        }
        .common .avatar{
          box-shadow: 0 20px 20px 0 $relative-shdw;
          margin-top: -10px;
        }
        .common{
          margin-bottom: 10px;
        }
    }
  }
}

/*third section with album and pdf link*/
.download-history{
  @include profileContent;
  min-height: 280px;
  img.albums{
    position: absolute;
    right: 0;
    top: 60px;
  }
  .description-pdf{
    margin-right: 290px;
  }
  .save-archive{
    @include engine;
    @include bigBlueButton;
  }
  .admin-btn{
    @include engine;
  }
  .download-pdf{
    position: relative;
    display: inline-block;
    height: 68px;
    overflow: hidden;
    width: 226px;
    .loading-pdf{
      display: none;
      position: absolute;
      left: -16px;
      top: -16px;
      width: 68px;
      height: 68px;
      transition: all 125ms linear;
    }
    a{
      @include engine;
      @include bigBlueButton;
      margin-right: 18px;
      height: 36px;
      width: 130px;
      opacity: 1;
      transition: all 125ms linear;
      text-align: center;
      span{
        display: inline-block;
        opacity: 1;
        height: 36px;
        overflow: hidden;
        transform: scaleX(1);
        transform-origin: 0 50% 0;
        transition: all 125ms linear;
      }
    }
    &.active{
      a{
        padding: 16px 34px;
        width: 0;
        span{
          opacity: 0;
          transform: scaleX(0);
        }
      }
      &.loading{
        a{
          opacity: 0;
        }
        .loading-pdf{
          display: block;
        }

      }
      &.success {
        /*a{
          opacity: 1;
        }*/
        a:after{
          content: '';
          display: block;
          position: absolute;
          left: 22px;
          top: 27px;
          width: 22px;
          height: 18px;
          @include inline-img($tick-white, 22px, 18px, center, center);
        }
      }
    }
  }

  .submit-reqiest{
    vertical-align: top;
    @include engine;
    @include bigBlueBorderButton;
    padding: 14px 24px;
  }
  h3{
    @include engine;
    font-size: em(48);
    line-height: 48px;
    &.archive{
      margin-top: 40px;
      margin-bottom: 9px;
    }
  }
}

/*relatives control*/
.control{
  a{
    color: $avatar-border;
    font-size: 30px;
    line-height: 30px;
    float: right;
    margin-top: 10px;
    &.cancel{
      margin-right: 16px;
    }
    &.unsubscribe{
      margin-left: 16px;
    }
    &.save{
      color: $blue-border;
    }
    &:hover{
      color: $red-hover;
    }

    i{
      vertical-align: middle;
    }
  }

}
