.comment-form{
  border-top: 1px solid $light-gray;
  padding-top: 32px;
  margin-bottom: 80px;
  .avatar{
    margin-top: 14px;
    margin-right: 18px;
    float: left;
  }
  form{
    margin-left: 58px;
    vertical-align: baseline;
    textarea{
      @include borderedTextarea;
      &::placeholder {
        color: $light-text;
      }
    }
    div{
      margin-right: 212px;
    }
    input[type="submit"]{
      @include engine;
      @include bigGreenButton;
      margin-left: 14px;
      float: right;
      display: block;
      &:disabled{
        @include bigDisabledButton;
      }
    }
  }
}

