.comments{
  li{
    padding-bottom: 24px;
    &.removing {
      @include slide_top('remove-comment', '0 0 24px 0');
      .edit {
        display: none;
      }
    }
    &.adding {
      @include slide_down('add-comment', '0 0 24px 0');
      .edit {
        display: none;
      }
    }
    hr{
      width: 120%;
      margin: 0 -10% 22px 0;
      background-color: $light-gray;
      border: none;
      height: 1px;

      @include breakpoint($tablet-desktop) {
        margin:0 0 30px;
        width: 100%;
      }
    }
    p{
      @include breakpoint($tablet-desktop) {
        padding-left:60px;
      }
    }
  }

}
