.is-it-you{
  @include engineRegular;
  h2 {
    font-size: em(32px);
    @include breakpoint($mobile) {
      font-size: em(26px);
    }
  }
  .user-avatar {
    margin: 45px auto 8px;
    position: relative;
    @include avatar(76px);
  }
  .user-name {
    font-size: em(28px);
    @include breakpoint($mobile) {
      font-size: em(26px);
    }
  }
  .user-social{
    width: 24px;
    height: 24px;
    position: absolute;
    top: -3px;
    right: 0;
    border-radius: 15px;
    border: white solid 2px;
    color: white;
  }
  .continue a{
    @include bigGreenButton;
    padding: 15px 35px;
    margin: 35px auto 10px;
    @include breakpoint($mobile) {
      font-size: em(23px);
    }
  }

  .not-you {
    font-size: em(30px);
    color: $another-green;
    cursor: pointer;
    margin: 10px auto 15px;
    transition: all 125ms linear;
    @include breakpoint($mobile) {
      font-size: em(23px);
    }
    &:hover{
      color: $red;
    }
  }
}
