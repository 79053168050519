.likes{
  .likes-num{
    color: $medium-text;
    display:inline-block;
    font-weight: 500;
    padding-left: 32px;
    margin-bottom: 14px;
    @include inline-img($like);
    line-height: 24px;
    transition: background 250ms ease;
    @include breakpoint($tablet-desktop) {
      margin-bottom: 18px;
    }
    &.enable{
      cursor: pointer;
      &.active{
        @include inline-img($like-pressed);
      }
      &:hover{
        @include inline-img($like-hover);
      }
    }
  }
  .likes-who{
    .avatar{
      display: none;
      margin-right: 6px;
      height: 40px;
      vertical-align: middle;
      &.visible{
        display: inline-block;
      }
    }

    .and-another {
      display: none;
      margin-left: 10px;
      font-weight: 500;
      color: $btn-blue;
      &:hover{
        color: $red-hover;
      }
    }
  }
}

.all-who-liked {
  height: 100%;
  max-height: 510px;
  h3 {
    @include engine;
    font-size: 32px;
    margin: 33px 45px 0;
  }
  .scrollable {
    display: block;
    height: 100%;
    max-height: 423px;
    position: relative;
    margin-top: 16px;
    .liked-list {
      display: inline-block;
      height: 100%;
      height: 423px;
      position: relative;
      padding: 0 26px;
      max-width: 588px;
      text-align: left;
      @include breakpoint(481px 676px) {
        width: 392px;
      }
      @include breakpoint(0px 480px) {
        width: 284px;
      }
      a{
        display: inline-block;
        margin: 0 26px;
        padding: 16px 0;
        vertical-align: top;
        width: 144px;
        text-align: center;
        @include breakpoint(0px 480px) {
          width: 110px;
          margin: 0 16px;
        }
        &:first-child{
          display: none;
        }
        .avatar{
          @include avatar(104px);
          @include breakpoint(0px 480px) {
            @include avatar(100px);
          }
          display: inline-block;
        }
        p{
          line-height: 18px;
        }
      }
    }
  }
}
