/*
* Footer
*/

footer {
  display: block;
  font-weight: 300;
  font-size: em(14);
  color: $footer-color;
  @include breakpoint($mobile) {
    padding-top: 26px;
  }
  @include breakpoint($tablet) {
    padding-top: 42px;
  }
  @include breakpoint($desktop) {
    padding-top: 33px;
  }

  .footer-content {
    width: 1170px;
    margin: 0 auto 33px;
    position: relative;
    @include breakpoint($mobile) {
      text-align: center;
      width: 240px;
      margin-bottom: 30px;
      padding-bottom: 26px;
    }
    @include breakpoint($tablet) {
      text-align: center;
      width: 450px;
      margin-bottom: 36px;
      padding-bottom: 26px;
    }

    ul {
      display: inline-block;
      margin: 4px 0 0 250px;
      padding: 0;
      width: 450px;
      text-align: center;
      @include breakpoint($mobile) {
        margin: 0;
        width: 100%;
        line-height: 20px;
      }
      @include breakpoint($tablet) {
        margin: 0;
      }
      li {
        display: inline-block;
        a {
          margin: 0 5px;
          color: $footer-color;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          text-decoration: underline;
        }
        a:hover {
          color: $red;
        }
      }
    }

    .copy {
      float: left;
      margin-top: 4px;
      @include breakpoint($mobile) {
        position: absolute;
        bottom: 0;
        left: 61px;
      }
      @include breakpoint($tablet) {
        position: absolute;
        bottom: 0;
        left: 166px;
      }
    }

    .social-footer {
      float: right;
      height: 23px;
      @include breakpoint($mobile) {
        margin-top: 18px;
        float: none;
        display: inline-block;
      }
      @include breakpoint($tablet) {
        margin-top: 12px;
        float: none;
        display: inline-block;
      }

      a {
        text-decoration: none;
        margin:0;
        padding: 0;
        width: 30px;
        height: 26px;
        display: inline-block;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: all 125ms linear;
        &.vk{
          @include inline-img($vk-gray, 25px, auto, center, center);
          &:hover{
            @include inline-img($vk-color, 25px, auto, center, center);
          }
        }
        &.fb{
          @include inline-img($facebook-gray, 12px, auto, center, center);
          &:hover{
            @include inline-img($facebook-color, 12px, auto, center, center);
          }
        }
        &.twi{
          @include inline-img($twitter-gray, 22px, auto, center, center);
          &:hover{
            @include inline-img($twitter-color, 22px, auto, center, center);
          }
        }
        &.ok{
          @include inline-img($ok-gray, 14px, auto, center, center);
          &:hover{
            @include inline-img($ok-color, 14px, auto, center, center);
          }
        }
        &.g{
          @include inline-img($google-gray, 22px, auto, center, center);
          &:hover{
            @include inline-img($google-color, 22px, auto, center, center);
          }
        }
     }

    }
  }
}
