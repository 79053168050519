.light {
  .jq-selectbox__select {
    height: 29px;
    padding: 13px 30px 0 8px;
    border-radius: 5px;
    background: $background-page;
    color: $menu;
  }
  .jq-selectbox__select:hover {
    background: $light-gray;
  }
  .jq-selectbox__select:active {

  }
  .jq-selectbox.focused .jq-selectbox__select {

  }
  .jq-selectbox.disabled .jq-selectbox__select {
    background: #F5F5F5;
    box-shadow: none;
    color: #888;
  }
  .jq-selectbox__trigger {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 100%;
    @include inline-img($drop, $posLeft: 12px, $posTop: 18px);
  }
  &.jq-selectbox:hover .jq-selectbox__trigger {
    @include inline-img($drop-black, $posLeft: 12px, $posTop: 18px);
  }
  .jq-selectbox__dropdown {
    position: relative !important;
    box-sizing: border-box;
    width: 100%;
    margin: -42px 0 0 0;
    padding: 0;
    border-radius: 5px;
    background: $background-page;

  }


  &.jq-selectbox ul {
    margin: 0;
    padding: 0;
    max-height: 244px !important;

  }
  &.jq-selectbox li {
    min-height: 18px;
    padding: 12px 8px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap;
    color: $menu;
    overflow-x: hidden;
  }
  &.jq-selectbox li.selected {
    /*background-color: #A3ABB1;
    color: #FFF;*/
  }
  &.jq-selectbox li:hover {
    background-color: $btn-blue;
    color: #FFF;
  }
  &.jq-selectbox li:first-child:hover {
    border-radius: 5px 5px 0 0;
  }
  &.jq-selectbox li:nth-last-child(3):hover {
    border-radius: 0 0 5px 5px;
  }
}
