//edit post form
.edit-post-content {
  width: 100%;
  display: none;
  textarea{
    @include baseFont;
    min-width: 100%;
    max-width: 100%;
    min-height: 78px;
    max-height: 78px;
    background-color: $popup-footer-bg;
    border: none;
    padding: 7px 16px;
    margin: 30px 0 13px;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;
    line-height: 26px;
    outline: none;
  }
  input{
    display: none;
  }
}

.form-control{
  position: absolute;
  bottom: 34px;
  right: 0;
  display: none;
  transition: all 5000ms linear;
  width: 100%;
  button{
    width: 173px;
    box-sizing: border-box;
    float: right;
    margin-left: 12px;
  }
  .save-edit-post{
    @include engine;
    @include bigGreenButton;
  }
  .cancel-edit-post{
    @include engine;
    @include bigGreenBorderButton;
  }
  .cancel-edit-post.disabled, .save-edit-post.disabled{
    @include bigDisabledButton;
  }
}

.post-media {
  .photo-editor-item {
    display: none;
    margin-right: 30px;
    margin-top: 1px;
    position: relative;
    width: 128px;
    height: 128px;
    vertical-align: top;
    border-radius: 4px;
  }
  .add-photo {
    display: none;
    width: 130px;
    height: 130px;
    margin-bottom: 20px;
    input[type="file"] {
      visibility: hidden;
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    label {
      display: inline-block;
      width: 130px;
      height: 130px;
      @include inline-img($add, $posLeft: center, $posTop: center);
      @include dotted-border(4px, 16, 26);
      &:hover{
        @include inline-img($add-hover, $posLeft: center, $posTop: center);
      }
      &.loading{
        cursor: default;
        &:hover{
          @include inline-img($add, $posLeft: center, $posTop: center);
        }
        svg:hover{
          stroke: $avatar-border;
        }
      }
    }
  }
}

.editable-content{
  position: relative;
  .media-item .close{
    display: none;
  }  
  &.editing{
    padding-bottom: 102px;
    .form-control,
    .edit-post-content,
    .media-item .close{
      display: inline-block;
    }
    .likes, .text{
      display: none;
    }
    
    .multi-photo{
      margin-bottom: 5px;
      .media-item{
        display: inline-block;
        margin-right: 30px;
        margin-top: 1px;
        margin-bottom: 20px;
        position: relative;
        width: 128px;
        height: 128px;
        vertical-align: top;
        &.adding{
          animation: slide_right 300ms linear;
        }
        &.removing{
          animation: slide_left 300ms linear;
        }
        .close{
          top:-18px;
          right: -18px;
        }
      }
      .full-photo{
        display: none;
      }
      .photo-editor-item{
        display: inline-block;
        margin-bottom: 20px;
      }
    }


  }
}



