@mixin robotoMedium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
@mixin engineRegular {
  font-family: 'engine', serif;
  font-weight: 400;
}
@mixin robotoLight {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
@mixin robotoBold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  background-size: $width $height;

  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    & {
      background-image: url($file + '@2x.' + $type);
      background-size: $width $height;
    }
  }
}

/*popup with triangle up*/
@mixin triangle-up ($color) {
  &::after {
    content: '';
    position: absolute;
    margin-left: -5px;
    left: 50%; top: -9px;
    border: 5px solid transparent;
    border-bottom: 5px solid $color;
  }
  &::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    box-shadow: 0 -5px 10px -3px rgba(0,0,0,.25);
    margin-left: -5px;
    left: 50%; top: 0;
  }
}

/*popup with triangle down*/
@mixin triangle-down ($color) {
  &::after {
    content: '';
    position: absolute;
    margin-left: -5px;
    left: 50%; bottom: -9px;
    border: 5px solid transparent;
    border-top: 5px solid $color;
  }
}

@mixin inline-img($name, $sizeX: false, $sizeY: false,
  $posLeft: left, $posTop: top, $repeat: no-repeat) {

  @if $sizeX == false {
     $sizeX: nth($name, 2);
  }
  @if $sizeY == false {
     $sizeY: nth($name, 3);
  }
  background: url(nth($name, 1)) $posLeft $posTop $repeat;
  background-size: $sizeX $sizeY;
}
@function inline-img-width($name) {
  @return nth($name, 2);
}
@function inline-img-height($name) {
  @return nth($name, 3);
}
@mixin close-btn{
  width: 32px;
  height:32px;
  border-radius: 18px;
  border: solid 3px $white;
  @include inline-img($cross-white, $posLeft: center, $posTop: center);
  background-color: $close-red;
  &:hover{
    background-color: $close-hover;
  }
}

@mixin dotted-border ($width, $dash, $gap) {
  position: relative;
  cursor: pointer;
  svg{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    stroke-dashoffset: 18;
    stroke-dasharray: $dash, $gap;
    stroke: $avatar-border;
    stroke-linecap:round;
    stroke-width: $width;
    stroke-linejoin: round;
    fill: none;
  }
  &:hover{
    svg{
      stroke: $green-hover;
    }
  }
}

@mixin dotted-add-circle () {
  transition: all 125ms linear;
  width: 150px;
  height: 150px;
  @include inline-img($add, 30px, 30px, 60px, 62px);
  @include dotted-border(4px, 16, 26);
  span {
    display: block;
    padding-top: 168px;
  }
  &:hover{
    color: $green-hover;
    @include inline-img($add-hover, 30px, 30px, 60px, 62px);
  }
}
@mixin deault-taks-img ($image-width) {
  background-color: $default-task-bg;
  background-repeat: no-repeat;
  background-position: center;
  @include background-image-retina($path + 'task_photo_default', 'png', $image-width, auto);
}

@mixin lightening ($width) {
  &:after{
    display: block;
    width: $width;
    height: 100%;
    background-image: linear-gradient(to left, #ffffff 0, rgba(255, 255, 255, 0.0) 100%);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
  }
}

