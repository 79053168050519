.unsubscribe{
  img{
    margin: 61px auto 42px;
    padding: 0;
    display: block;
    @include breakpoint($mobile) {
      margin: 20px auto;
    }
  }
  p.small-text{
    padding: 10px 0 20px 0;
  }
  .unsubscribe-block, .subscribe-block{
    overflow: auto;
  }
  .btn.blue, .btn.green{
    @include breakpoint($mobile) {
      font-size: 26px;
      margin-bottom: 12px;
    }
  }
}
