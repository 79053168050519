/* Magnific Popup CSS */

@import "settings";
@import "general";
@import "appearance";

// default inline popup
.white-popup {
  position: relative;
  display: inline-block;
  border-radius: 12px;
  background-color: $white;
  border: solid 1px $border-gray !important;
  box-shadow: 0 22px 30px 0 rgba(0, 0, 0, 0.1);
}

// Move-from-top effect
.slidingTopCenter {

  &.mfp-bg {
    opacity: 0;
    transition: all 125ms;
  }

  .mfp-content {
    text-align: center;
    box-sizing: border-box;
    position: absolute;
    transform: translateY(-110%);
    top: 0;
    left: 0;
    padding-left: $mfp-popup-padding-left-mobile;
    padding-right: $mfp-popup-padding-left-mobile;
    width: 100%;
    margin: 0;
  }

// animate in
  &.mfp-ready {
    .mfp-content {
      transform: translateY(-50%);
      opacity: 1;
      top: 50%;
      left: 0;
      margin: 0;
      animation: none;
    }
    &.mfp-bg {
      opacity: $mfp-overlay-opacity;
    }
  }

  &.animated .mfp-content {
    animation: show_center 600ms linear;
  }
// animate out
  &.mfp-removing {
    .mfp-content {
      top: 50%;
      left: 0;
      animation: hide_center 600ms linear;
      animation-fill-mode: forwards;
    }
    &.mfp-bg {
      opacity: $mfp-overlay-opacity;
    }
  }

  &.alignTop {
    &.mfp-ready .mfp-content {
      top: 0;
      transform: translateY(0px);
      animation: none;
    }
    &.animated .mfp-content {
      animation: show_top 600ms linear;
    }
    &.mfp-removing .mfp-content {
      animation: hide_top 600ms linear;
      animation-fill-mode: forwards;
    }
  }

  &.withBanner {
    top: 80px !important;
  }

}

//iframe popup (without animation)
.mfp-iframe-holder {
  .mfp-content {
    display: inline-block;
    vertical-align: middle;
    max-width: 90%;
    width: 900px;
    height: 523px;
    max-height: 90%;
    @include breakpoint($mobile-portrait) {
      max-height: 50%;
    }
    .mfp-iframe-scaler,
    .mfp-iframe {
      height: 100%;
      width: 100%;
    }
  }
}


.popupsContainer {
  position: fixed;
  transform: translateY(-110%);
  z-index: -1;
  visibility: hidden;
}
