@import 'premium_popup';

section.tasks-control {
  padding-top: 47px;
  @include breakpoint($mobile) {
    position: relative;
    width: 100%;
    padding: 18px 0 0 ;
  }
  .main-task-list-header {
    @include engine;
    font-size: em(32);
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 10px;
    @include breakpoint($tablet-desktop) {
      display: none;
    }
  }
  .task-list-header {
    @include engine;
    position: relative;
    font-size: em(48);
    margin: -17px 0 7px;
    @include breakpoint($mobile) {
      margin: 0;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      text-align: center;
    }
    .task-tag {
      @include engine;
      @include button;
      @include big;
      font-size: 30px;
      background-color: $light-blue;
      color: $white;
      padding: 16px 32px;
      cursor: default;
    }
  }
  .text-description {
    @include text-desription;
    margin: 0 0 17px;
    &.tag-description {
      margin: 0;
    }
    @include breakpoint($mobile) {
      padding: 17px 10px 0;
    }
  }
  .tags-list {
    margin-top: 20px;
    text-align: center;
    @include breakpoint($mobile) {
      margin-top: 0;
    }
    .task-tag {
      @include engine;
      @include button;
      @include big;
      background-color: $light-blue;
      color: $white;
      padding: 16px 32px;
      margin: 20px 4px 0;
      &:hover {
        background-color: $light-blue-hover;
      }
    }
  }

  #ages_input {
    visibility: hidden;
  }
  .btn {
    display: inline-block;
    margin-top: 20px;
    margin-right: 10px;
    padding: 15px 29px 16px;
    color: $white;
    font-family: 'engine';
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    .checkbox {
      display: inline-block;
      vertical-align: middle;
      margin-right: 3px;
      @include breakpoint($tablet-desktop) {
        display: none;
      }
    }

    &.all {
      padding-left: 35px;
      padding-right: 31px;
      &:not(.active) {
        background-color: $btn-disabled;
        cursor: default;
        &:hover {
          @include breakpoint($desktop) {
            background-color: $btn-disabled;
          }
        }
      }
    }
    &:not(.active) {
      background-color: $btn-gray;
      color: $white;
      &:hover {
        @include breakpoint($desktop) {
          background-color: $btn-gray-hover;
        }
      }
    }
    @include breakpoint($mobile-tablet-portrait) {
      font-size: 26px;
    }
    @include breakpoint($mobile) {
      padding: 8px 16px 9px;
      font-size: 24px;
      &.active, &:not(.active), &:hover, &:active, .all-text {
        background: $white;
        color: $text;
        width: 100%;
        border-radius: 0;
        margin: 0;
        border-bottom: 1px solid $range-input-line;
        text-align: left;
      }
      &.all {
        display: none;
      }
    }
    .icon{
      width: 37px;
      height: 37px;
      vertical-align: middle;
      display: inline-block;
      @include breakpoint($mobile-tablet-portrait) {
        height: 26px;
      }
      @include breakpoint($mobile) {
        display: none;
      }
    }
    span {
      vertical-align: middle;
    }
  }
  .btns {
    @include breakpoint($mobile) {
      max-height: 0;
      overflow: hidden;
      transition: all 300ms linear;
      &.opened {
        max-height: 800px;
      }
    }
  }
  .mobile-all {
    position: relative;
    font-size: 24px;
    background: $white;
    color: $text;
    border-radius: 0;
    margin: 0;
    border-bottom: 1px solid $range-input-line;
    text-align: left;
    width: 100%;
    padding: 18px 0;
    @include breakpoint($tablet-desktop) {
      display:none;
    }
    span {
      margin-left: 16px;
    }
    .close {
      @extend .circle-close;
      background-color: $close-red;
      width: 22px;
      height: 22px;
      background-size: 10px auto;
      border-radius: 11px;
      display: none;
      transition: all 125ms linear;
      margin: -5px 3px 0 16px;
      vertical-align: middle;
      &.shown {
        display: inline-block;
        & + span {
          margin-left: 0;
        }
      }
    }
    &:after {
      content: "";
      display: block;
      width: 15px;
      height: 8px;
      @include inline-img($drop, $posLeft: center, $posTop: center);
      position: absolute;
      right: 15px;
      top: 28px;
      transition: 125ms linear;
    }
    &.short {
      &:after {
        transform: rotate(-90deg);
      }
      .close {
        display: none;
        &.shown {
          display: inline-block;
        }
      }
    }
  }

  .btns_list {
    overflow: auto;
    text-align: center;
    @include breakpoint($mobile) {
      margin: 13px 10px 0;
      //width: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      border-top: solid 0.5px rgba(0, 0, 0, 0.1);
    }
  }

  .tasks-menu {
    height: 48px;
    margin-top: -17px;
    margin-bottom: 29px;
    position: relative;
    @include breakpoint($tablet) {
      padding-top:5px
    }
    @include breakpoint($mobile) {
      display: none;
    }
    a {
      color: $btn-blue;
      font-size: em(48);
      font-family: "Engine";
      margin-right: 40px;
      &:hover {
        color: $red-hover;
      }
      &.active {
        color: $text;
      }
      @include breakpoint($tablet) {
        font-size: em(36);
        margin-right: 20px;
      }
    }
    .search {
      position: absolute;
      right: 0;
      top:0;
    }
  }
}

.tasks-list.all,
.another-tasks-list.all{
  padding-top: 20px;
  padding-bottom: 26px;
  @include breakpoint($mobile) {
    padding: 10px 10px 0;
  }
  li {
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
  .task-person {
    position: relative;
    padding-bottom: 23px;
    min-height: 54px;
    @include breakpoint($mobile) {
      padding-bottom: 11px;
    }
    .task-name {
      margin-bottom: 4px;
      margin-left: 20px;
      @include breakpoint($mobile) {
        margin-left: 10px;
      }
    }
    span {
      margin-left: 20px;
      @include breakpoint($mobile) {
        margin-left: 10px;
      }
    }
    &.users-task {
      .task-name {
        width: 77%;
      }
      .star {
        position: absolute;
        right: 20px;
        top: 25px;
      }
    }
  }
}

.tasks-no-results {
  .giraffe-img {
    margin: auto;
    position: relative;
    margin-bottom: 30px;
    width: 280px;
    img {
      width: 280px !important;
    }
  }

  .message {
    width: 100%;
    height: auto;
    font-family: Engine;
    font-size: em(48px);
    font-weight: normal;
    line-height: 1.1;
    text-align: center;
    color: $menu;
    @include breakpoint($mobile) {
      font-size: em(32px);
    }
  }
}
.star {
  display: block;
  width: 24px;
  height: 23px;
  @include inline-img($star-off);
  &.active {
    @include inline-img($star-on);
  }
}
