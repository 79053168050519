.task-report {
  margin: 20px 0 13px;
  h3 {
    @include engine;
    font-size: em(32);
    margin-top: 36px;
  }
  .avatar {
      position: absolute;
      left: 0;
      top: 11px;
    }

  .task-report-media {
    margin: 48px 0 7px;
    .media-item{
      position: relative;
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 20px;
      position: relative;
      width: 108px;
      height: 108px;
      vertical-align: top;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &.adding{
        animation: slide_right 300ms linear;
      }
      &.removing{
        animation: slide_left 300ms linear;
      }
      .close{
        top:-18px;
        right: -10px;
        @include close-btn;
        position: absolute;
        border-width: 2px;
      }
      .photo-editor-item{
        border-radius: 8px;
        width: 108px;
        height: 108px;
      }
      .full-photo{
      display: none;
    } 
    }
   
    .add-photo {
      display: inline-block;
      margin-bottom: 20px;
      width: 108px;
      height: 108px;
      input[type="file"] {
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      label {
        display: inline-block;
        border-radius: 8px;
        color: $btn-blue;
        width: 108px;
        height: 108px;
        font-weight: 500;
        @include inline-img($camera, $posLeft: center, $posTop: 39px);
        background-color: $add-photo-bg;
        padding-top: 73px;
        box-sizing: border-box;
        text-align: center;
        &:hover{
          cursor: pointer;
          color: $red-hover;
          @include inline-img($camera-hover, $posLeft: center, $posTop: 39px);
          background-color: $add-photo-bg;
        }
        &.loading{
          cursor: default;
          &:hover{
            color: $btn-blue;
            @include inline-img($camera, $posLeft: center, $posTop: 39px);
            background-color: $add-photo-bg;
          }
        }
      }
    }
  }

  .task-report-form {
    position: relative;
    padding-left: 60px;
    input[type="submit"] {
      @include engine;
      @include bigBlueButton;
      float: right;
      margin: 20px 0 17px;
    }
    div {
      margin-right: 32px;
    }
    textarea {
      @include borderedTextarea;
    }
    .send-report-btn.disabled, .send-report-btn.disabled:hover {
      background-color: $btn-disabled;
      cursor: default;
    }
  }

  .task-complete-form {
    position: relative;
    input[type="button"] {
      @include engine;
      @include bigBlueButton;
      float: right;
      margin: 20px 0 17px;
    }
    div {
      margin-right: 32px;
    }
    .alert-info {
      margin: 12px 0;
      padding: 21px 16px;
      box-sizing: border-box;
      background-color: $omfg-blue;
      border-radius: 8px;
      font-weight: 500;
      color: $white;
    }
  }
}
