.errors{
  position: fixed;
  right: 24px;
  top: 12px;
  z-index: 10000;
}
.undefined_error {
  background-color: $white;
  border: solid 1px $red;
  box-sizing: border-box;
  font-size: em(14);
  line-height: 1.57;
  width: 384px;
  padding: 32px;
  margin: 12px 0;
  position: relative;
  &.common_error {
    border-color: $border-gray;
  }
  &.removing {
    @include slide_top('error', '32px');
  }
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 12px;
    height: 11px;
    cursor: pointer;
    @include inline-img($cross, $posLeft: center, $posTop: center);
    &:hover,
    &:focus {
      @include inline-img($cross-red, $posLeft: center, $posTop: center);
    }
  }
}
