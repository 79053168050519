.application{
  a{
    border-radius: 8px;
    display: block;
    width: 162px;
    height: 48px;
    transition: background-color 125ms linear;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &.appStore{
      @include inline-img($app-store, 82%, auto, center, center);
      background-color: $black;
    }
    &.googlePlay{
      @include inline-img($google-play, 86%, auto, center, center);
      background-color: $black;
    }
    &:hover{
      background-color: $violet;
      transition: background-color 125ms linear;
    }
  }
}

header .application{
  display: block;
  float: right;
  a{
    float: right;
    margin: 26px 20px 26px  0 ;
  }

  @include breakpoint($mobile) {
    display: none;
  }

  @include breakpoint($tablet) {
    a{
      margin: 16px 20px 16px 0;
    }
  }
}
header.swim .application{
  @include breakpoint($desktop) {
    margin-right: 70px;
    a{
      margin: 16px 20px 16px 0;
    }
  }
}

footer .application{
  display: block;
  width: 300px;
  height: 47px;
  margin: 0 auto 30px;
  a{
    width: 140px;
    &.googlePlay{
      float: left;
      margin-right: 10px;
    }
    &.appStore{
      float: right;
    }
  }
  @include breakpoint($tablet-desktop) {
    display: none;
  }
}
