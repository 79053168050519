.buy-premium{
  text-align: center;
  padding: 20px;
  h3{
    font-family: 'Engine';
    font-size: em(32);
    line-height: 1.25;
    margin: 12px 25px 8px;
    @include breakpoint($mobile-tablet) {
      font-size: em(24px);
    }
  }
  p{
    font-size: em(14);
    padding: 0;
  }
  img{
    margin: 32px 0 40px;
    width: 480px;
  }
  .application a{
    display: inline-block;
    margin: 0 9px 12px;
  }
}
