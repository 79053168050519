/*for easy change avatar size*/
@mixin avatar ($size) {
  width: $size;
  height: $size;
  background-size: $size $size;
  border-radius: $size/2;
  img{
    width: $size;
    height: $size;
    border-radius: $size/2;
  }
}

.avatar {
  @include avatar(40px);
  &.boy{
    @include inline-img($boy, 40px, 40px);
  }
  &.girl{
    @include inline-img($girl, 40px, 40px);
  }
  &.female{
    @include inline-img($mom, 40px, 40px);
  }
  &.male{
    @include inline-img($dad, 40px, 40px);
  }
  &.secret{
    @include inline-img($secret, 40px, 40px);
  }

  &.medium-60{
    @include avatar(60px);
  }
  &.medium-76{
    @include avatar(76px);
  }
  &.big{
    @include avatar(76px);
    @include breakpoint($tablet-desktop) {
      @include avatar(152px);
    }
  }
  &.big-256{
    @include avatar(256px);
  }
  &.empty{
    transition: all 125ms linear;
    @include inline-img($memory, $posLeft: center, $posTop: center);
    background-color: $background-page;
    position: relative;
    svg{
      transition: all 125ms linear;
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      stroke-dasharray: 10, 12;
      stroke: $avatar-border;
      stroke-linecap:round;
      stroke-width: 3px;
      stroke-linejoin: round;
      fill: none;
    }
    &:hover {
      @include inline-img($memory-hover, $posLeft: center, $posTop: center);
      background-color: $background-page;
      svg {
        stroke: $green-hover;
      }
    }
    &.disabled {
      cursor: default;
      @include inline-img($memory, $posLeft: center, $posTop: center);
      background-color: $background-page;
      svg {
        stroke: $avatar-border;
      }
    }
  }
}
