$black: #000;
$white: #fff;
$violet: #a126d9;
$red: #de352d;
$another-red: #df6155;
$close-hover: #cd3d2c;//close btn bg
$close-red: #ee513a;
$red-hover: #d63624; //all links hovers
$exit-red: #ee513a;
$light-red: #f3c6c1;//hover for light-gray link
$orange: #efa321;
$error-border-red: #f2c3bd;

/*task category colors */
$yellow: #f4dc3d;
$yellow-hover: #EDCF28;
$light-blue: #72d2f6;
$light-blue-hover: #5EC2E8;
$violet-category: #ce54f6;
$violet-category-hover:#BF41E8;
$akvamarin: #43ca99;
$akvamarin-hover: #36B989;
$light-green: #70d252;
$light-green-hover: #64C445;
$pink: #f84976;
$pink-hover: #E53664;
$another-pink: #f7406b;
$light-orange: #f89349;
$light-orange-hover: #E7863E;
$сategory9: #98e3ff;
$сategory9-hover: #87D3EF;
$сategory10: #7083c3;
$сategory10-hover: #6175B6;

$green: #36ac37;
$another-green: #00ce6c;
$dark-green: #35b860;
$green-hover: #34b93d;


$blue: #4a90e2;
$another-blue: #87cdde;
$omfg-blue: #1a9aff;
$btn-blue: #00aeef; //background color for blue btn
$btn-blue-hover: #299adc;
$blue-border: #0db2ef;
$radio-blue: #3eadff; //blue for radio button

$gray: #a2a9b0;
$light-gray: #ededed;
$dark-gray: #333; //common text color
$another-gray: #4a4a4a;
$omfg-gray: #f1f1f1;
$footer-color: #6b6b6b;
$entrance-gray: #b3b3b3;

$relative-shdw: #f2f2f0; //relative in profile
$label-gray: #e8e5e5;
$btn-gray: #e2e2e2 ; //background color for gray btn, checkbox background gray, in invite code popup copy & reload icon
$btn-gray-hover: #d2d2d2 ;
$btn-disabled: #cdcdcd;
$menu-bg: #353535; // left menu in mobile version
$grey: #222; // active menu in mobile version
$exit-btn: #aaa; // exit btn in mobile menu
$range-input-line: #d8d8d8;
$default-task-bg: #e5e5e5;
$close-gray: #c5c5c5;
$border-gray: #c4d1d3;

/*new colors*/
$avatar-blue: #98e3ff; //avatar bg
$avatar-pink: #ff98ab; //avatar bg
$text: #333; //common text color
$light-text: #a0a0a0; //light gray text
$medium-text: #999999; // medium gray text
$background-page: #F8F8F8; //page background gray
$checkbox-bg-hover: #bfbfbf;
$thead: #a7a7a7;
$menu: #a3a3a3; //menu text color
$avatar-border: #d6d6d6; //dotted border in empty avatar(profile page)
$popup-footer-bg: #f0f0f0;
$form-text: #262626; //new text color in forms

/*labels*/
$pink-label: #ff6460;

/*socials*/
$vk-bg: #4c75a3;
$fb-bg: #3b5998;
$ok-bg: #ed8300;
$go-bg: #dd4b39;
$tw-bg: #54abee;

$gold: #ffc41a;

$alert-bg: #ff9e9e;
$add-photo-bg: #e9e9e9;
$footer-login-bg: #e4e4e4;

