.social_sharing_panel {
  @include breakpoint($mobile) {
    display: none;
  }
  position: fixed;
  top: 36%;
  width: 40px;
  height: 136px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

  a {
    display: block;
    margin-top: 8px;
    margin-left: 8px;
    width: 24px;
    height: 24px;
    border-radius: 1.4px;

    &.facebook {
      @include inline-img($facebook, 7.8px, auto, 8px, 5px);
      background-color: #3b5998;
      &:hover {
        background-color: #394f8a;
      }
    }

    &.google {
      @include inline-img($google, 14.6px, auto, 5.4px, 6px);
      background-color: #dd4b39;
      &:hover {
        background-color: #bf442d;
      }
    }

    &.odnoklassniki {
      @include inline-img($ok, 9px, auto, 7px, 4.7px);
      background-color: #ed8300;
      &:hover {
        background-color: #cd7600;
      }
    }

    &.vkontakte {
      @include inline-img($vk, 14.6px, auto, 4px, 8px);
      background-color: #4c75a3;
      &:hover {
        background-color: #4a6994;
      }
    }

    &.twitter {
      @include inline-img($twitter, 13.4px, auto, 5.4px, 6px);
      background-color: #54abee;
      &:hover {
        background-color: #4695d2;
      }
    }
  }
}
