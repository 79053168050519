header {
  width: 100%;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  background-color: $white;
  min-height: 80px;
  @include breakpoint($desktop) {
    min-height: 100px;
  }
  @include breakpoint($tablet-desktop) {
    position: absolute;
    top:0;
    z-index: 2;
  }
  &.swim {
    @include breakpoint($tablet-desktop) {
      position: fixed;
      top:0;
      left:0;
      z-index: 101;
      border-bottom: rgba(0, 0, 0, 0.1) solid 1px ;
      background: rgba(255, 255, 255, 0.9);
      animation: show 100ms ease-in-out;
      min-height: 80px;
      .user-menu {
        top: 75px;
      }
    }
    @include breakpoint($desktop) {
      .logo {
        float: left;
        width: 72px;
        height: 65px;
        margin: 3px 0 0 107px;
        background: left 1px no-repeat;
        @include background-image-retina($path + 'logo_s', 'png', 53px, 65px);
      }
      .guest-menu {
        margin-top: 16px;
      }
      menu {
        .user-link {
          margin: 10px 90px 10px 0;
        }
        li {
          border: none;
          width: auto;
          padding: 13px 0 11px;
        }
      }
      .lang {
        display: none;
      }
    }
    @include breakpoint(1200px 1299px) {
      .logo {
        margin-left: 57px;
      }
    }
  }
  .logo {
    display: block;
    @include breakpoint($mobile) {
      width: 160px;
      height: 80px;
      background: center 20px no-repeat;
      background-color: $white;
      position:relative;
      z-index: 11;
      margin: 0 auto;
      @include background-image-retina($path + 'log_text', 'png', auto, 48px);
    }
    @include breakpoint($tablet) {
      //float: left;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 72px;
      height: 65px;
      margin: 3px 0 0 20px;
      background: left 1px no-repeat;
      @include background-image-retina($path + 'logo_s', 'png', 53px, 65px);
    }
    @include breakpoint($desktop) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 120px;
      height: 72px;
      margin: 12px 0 16px 90px;
      background: left top no-repeat;
      @include background-image-retina($path + 'logo', 'png', 120px, 72px);
    }
    @include breakpoint(1200px 1299px) {
      margin-left: 40px;
    }
  }

  /* lang buttons*/
  .lang {
    float: right;
    margin: 34px 28px 0 18px;
    a, span {
      display: block;
      @include robotoLight;
      font-size: 12px;
      margin-bottom: 3px;
      margin-right: 8px;
      color: $dark-gray;
      text-decoration: underline;
      text-transform: uppercase;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      &:hover {
        color: $red;
      }
      &.active {
        text-decoration: none;
      }
      &.active:hover {
        color: $dark-gray;
        cursor: default;
      }
    }

    @include breakpoint($mobile-tablet) {
      display: none;
    }
  }

  /* not login menu*/
  .guest-menu {
    li {
      display: inline-block;
      //vertical-align: middle;
      margin: 0 20px;
      a {
        display: inline-block;
        line-height: 19px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        @include robotoMedium;
        font-size: 16px;
        text-decoration: none;
        color: $btn-blue;
        padding: 15px 0 14px;
        transition: color 125ms linear;
        &.make-login-popup {
          @include bigBlueButton;
          padding: 15px 19px 14px 23px;
          font-size: 16px;
          line-height: 19px;
        }
      }
      @include breakpoint($mobile-tablet) {
        &.desktop {
          display: none;
        }
      }
      .play {
        background-size: 47px;
        width: 47px;
        height: 47px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -3px;
        margin-right: 5px;
        cursor:pointer;
        transition: background 125ms linear;
      }
      &:hover {
        .play {
          @include inline-img($play-hover, 47px, 47px, center);
        }
        a {
          color: $red-hover;
        }
      }
      &.active a {
        color: $text;
        cursor: default;
        pointer-events: none;
      }
    }
    @include breakpoint($mobile) {
      display: block;
      margin: 12px auto 0;
      text-align: center;
    }
    @include breakpoint($tablet) {
      float: left;
      margin: 16px 0 0 92px;
    }
    @include breakpoint($desktop) {
      float: left;
      margin: 26px 0 0 230px;
    }
    @include breakpoint(1200px 1299px) {
      margin-left: 170px;
    }
  }

  /*login menu*/
  menu {
    @include breakpoint($mobile) {
      position: absolute;
      display: none;
      top: 80px;
      left: 0;
      width: 100%;
      z-index: 10;
      background-color: $white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    }
    @include breakpoint($tablet) {
      margin-left: 92px;
    }
    @include breakpoint($desktop) {
      margin-left: 230px;
    }
    li {
      border-top: 1px solid $background-page;
      display: block;
      float: left;
      width: 100%;
      @include breakpoint($tablet-desktop) {
        border: none;
        width: auto;
        padding: 13px 0 11px;
      }
      @include breakpoint($desktop) {
        border: none;
        width: auto;
        padding: 23px 0 21px;
      }
      & > a {
        padding: 20px;
        display: block;
        font-weight: 500;
        color: $btn-blue;
        .avatar {
          display: inline-block;
          vertical-align: middle;
          float: left;
          margin-right: 21px;
        }
        .user-name {
          font-family: 'engine';
          font-size: em(32);
          display: block;
          color: $text;
        }
        .footnote {
          color: $btn-gray-hover;
        }
      }
      &.user-link {
        @include breakpoint($tablet-desktop) {
          float: right;
          margin: 22px 90px 18px 0;
          padding: 0;
          width: 60px;
        }
        @include breakpoint($tablet) {
          margin: 10px 20px 10px 0;
        }
        a {
          line-height: 30px;
          @include breakpoint($tablet-desktop) {
            padding: 0;
            span {
              display: none;
            }
          }
        }
      }
      &.admin-link a,
      &.exit a {
        color: $exit-red;
      }
      &.active a {
        color: $text;
        cursor: default;
        pointer-events: none;
      }

    }
    @include breakpoint($tablet-desktop) {
      .user-menu {
        @include popup;
        right: 90px;
        top: 92px;
        padding-bottom: 5px;
        &:after, &:before {
          left: auto;
          right: 25px;
        }
        li {
          float: none;
          min-width: 160px;
          padding: 0 20px 15px;
          &.user-link {
            border-bottom: 1px solid $btn-gray;
            padding: 20px 20px 13px;
            margin: 0 0 20px 0;
          }
          &.admin-link a {
            color: $exit-red;
          }
          a {
            color: $text;
            padding: 0;
            span {
              display: block;
              transition: color 125ms linear;
            }
            &:hover, &:hover span {
              color: $red-hover;
              .footnote {
                color: $light-red;
              }
            }
          }
        }
      }
    }
    @include breakpoint($tablet) {
      .user-menu {
        right: 20px;
        top: 73px;
      }
    }
    @include breakpoint($mobile) {
    .user-menu .user-link {
        display: none;
      }
    }
  }

  /*special login menu for mobile*/
  .overlay,.menu-btn {
    display: none;
  }
  @include breakpoint($mobile) {
    .overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
    .close {
      display: none;
      @include inline-img($cross-red, 22px, 20px);
      width: 22px;
      height: 20px;
      position: absolute;
      left: 20px;
      top: 31px;
      z-index: 12;
    }
    .menu-btn {
      display:block;
      position: absolute;
      top: 31px;
      left: 20px;
      @include inline-img($menu-icon);
      width: 22px;
      height: 20px;
      z-index: 12;
    }
  }

}

.main {

  header {
    border-bottom: $light-gray solid 1px ;
    box-shadow: none;
    @include breakpoint($mobile) {
      border: none;
    }
  }
}
