.breadcrumbs {
  display: inline-block;
  margin: 0 15px 15px;
  max-width: 640px;
  min-width: 320px;

  ul {
    display: inline;

    li {
      display: inline;

      a {
        color: $btn-blue;
        font-weight: 500;
        &:hover {
          color: $red-hover;
        }
      }
    }

    li + li:before {
      display: inline-block;
      padding-right: .4rem;
      padding-left: .5rem;
      font-weight: 300;
      color: $another-gray;
      content: ">";
    }
  }
}

@media screen and (min-width: 1097px) {
  .breadcrumbs {
    width: 100%;
    margin: 0 236px 15px 150px;

    ul {
      align-items: center;
      justify-content: center;
      display: flex;

      li {
        float: left;
        display: block;
      }
    }
  }
}
