.content-download{
  max-width: 640px;
  width: 100%;
  overflow: hidden;
  min-height: 172px;
  h3{
    @include engine();
    font-size: em(32px);
    margin: 32px 20px 10px;
  }
  p{
    line-height: 22px;
    text-align: left;
    margin: 0 20px;
    padding: 22px 0 32px;
  }
  .footer{
    float: left;
    width: 100%;
    padding: 24px 0;
    background-color: $popup-footer-bg;
    .send-confirm, .sended-confirm{
      width: 460px;
      @include engine();
      .sended {
        display: none;
      }
    }
    .settings, .send-confirm{
      @include bigBlueButton();
    }
    .sended-confirm{
      @include bigGreen();
      pointer-events: none;
    }
    .settings {
      @include engine;
    }
  }
}

