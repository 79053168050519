section.tasks-new {
  position: relative;
  z-index: 3;
  background-color: $omfg-gray;
  text-align: center;
  width: 100%;
  float: left;
  padding-bottom: 32px;

  h2 {
    @include engineRegular;
    @include breakpoint($mobile) {
      font-size: 32px;
      padding-top: 0;
    }
    @include breakpoint($tablet) {
      font-size: 48px;
      padding-top: 0;
    }
    color: $another-pink;
    font-size: 64px;
    padding-top: 50px;
  }

  .tasks-new-list {
    @include breakpoint($mobile) {
      width: 100%;
      padding-top: 25px;
      column-count: 1;
    }

    @include breakpoint($tablet) {
      width: 100%;
      padding-top: 50px;
      column-gap: 20px;
    }

    @include breakpoint($desktop) {
      width: 1200px;
      padding-bottom: 40px;
    }
    text-align: left;
    margin: 0 auto;
    padding-top: 70px;
    column-count: 2;
    column-gap: 45px;

    li {
      &.task-category {
        @include breakpoint($mobile) {
          width: 90%;
          margin-bottom: 24px;
        }
        @include breakpoint($tablet) {
          width: 450px
        }
        break-inside: avoid-column;
        margin-bottom: 40px;
        width: 535px;
        margin-left: 17px;
        border-radius: 10px;
        background-color: $white;

        .task-category-header {
          border-radius: 10px 10px 0 0;
          display: inline-block;
          width: 100%;

          &:hover {
            color: $white;
          }

          .icon {
            @include breakpoint($mobile) {
              width: 24px;
              height: 24px;
              margin: 12px 14px 12px 12px;
            }
            width: 34px;
            height: 34px;
            float: left;
            margin: 19px 21px 19px 19px;
            background-color: inherit;
          }

          h4 {
            @include engineRegular;
            @include breakpoint($mobile) {
              max-width: 70%;
              padding-top: 8px;
              padding-bottom: 7px;
              font-size: 24px;
            }
            @include breakpoint($tablet) {
              max-width: 70%;
              padding-bottom: 7px;
            }
            font-size: 36px;
            color: $white;
            line-height: 1.33;
            float: left;
            padding-top: 11px;
          }

          .icon-action {
            @include breakpoint($mobile) {
              @include inline-img($action, 24px, 24px);
              margin-top: 13px;
              margin-right: 10px;
              width: 24px;
              height: 24px;
            }
            @include inline-img($action, 30px, 30px);
            width: 30px;
            height: 30px;
            object-fit: contain;
            float: right;
            margin-top: 21px;
            margin-right: 16px;
          }
        }

        ul {
          @include breakpoint($mobile) {
            padding: 7px 20px;
          }
          padding: 10px 20px;
          padding-bottom: 5px;

          li {
            @include breakpoint($mobile) {
              padding-bottom: 5px;
            }
            padding: 0;
            padding-bottom: 12px;

            h6 {
              @include robotoMedium;
              font-size: 16px;
              line-height: 0.9;
              color: $black;
              padding-bottom: 3px;
            }

            span {
              @include robotoLight;
              font-size: 14px;
              line-height: 1.5;
              color: $light-text;
            }
          }
        }
      }
    }
  }
}
