.login-form{
    h2 {
      @include engineRegular;
      font-size: em(32px);
      margin: 0 0 20px;
    }
    .sms{
      @include bigGreenButton;
    }
    .make-login-email{
      @include bigBlueButton
    }
    .sms, .make-login-email{
      @include engineRegular;
      width: 270px;
      padding: 16px 0;
      margin: 9px;
      @include breakpoint($mobile) {
        width: 203px;
        font-size: em(28px);
      }
    }
    h6{
      @include engineRegular;
      font-size: em(22px);
      margin-top: 13px;
      margin-bottom: 20px;
      color: $entrance-gray;
    }
}
