//labels
.label{
  font-size: em(14);
  font-weight: 500;
  border-radius: 17px;
  padding: 4px 9px;
  &.pink{
    color: $white;
    background-color: $pink-label;
  }
  &.gray{
    color: $text;
    background-color: $label-gray;
    &:hover{
      color: $text;
      background-color: $label-gray;
    }
  }
  &.big{
    font-size: em(18);
    font-weight: bold;
    padding: 11px 14px;
    border-radius: 20px;
  }
  &.ball{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
  }
}

//buttons
@mixin button {
  border-radius: 36px;
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 125ms linear;
  outline:none;
}
@mixin big{
  font-size: em(30);
  line-height: 36px;
  padding: 16px 39px;
}
@mixin blueButton { 
  @include button;
  background-color: $btn-blue;
  color: $white;
  &:hover{
    color: $white;
    background-color: $btn-blue-hover;
  }
}

@mixin bigBlueButton {
  @include big;
  @include blueButton;
}

@mixin bigBlueBorderButton {
  @include button;
  @include big;
  background-color: $white;
  border: solid 2px $blue-border;
  color: $btn-blue;
  &:hover{
    color: $white;
    background-color: $btn-blue-hover;
    border-color: $btn-blue-hover;
  }
}
@mixin bigGrayButton {
  @include button;
  @include big;
  background-color: $btn-gray;
  color: $white;
  &:hover{
    color: $white;
    background-color: $btn-gray-hover;
  }
}
@mixin bigDisabledButton {
  @include button;
  @include big;
  background-color: $btn-gray;
  color: $white;
  cursor: default;
  &:hover{
    color: $white;
    background-color: $btn-gray;
  }
}
@mixin bigGreenButton {
  @include button;
  @include big;
  background-color: $another-green;
  color: $white;
  &:hover{
    background-color: $green;
    color: $white;
  }
}
@mixin bigGreenBorderButton {
  @include button;
  @include big;
  border-radius: 36px;
  background-color: $white;
  border: solid 2px $another-green;
  color: $another-green;
  padding: 14px 39px;
  &:hover{
    color: $white;
    background-color: $green;
    border-color: $green;
  }
}

@mixin bigGreen {
  @include big;
  border-radius: 36px;
  display: inline-block;
  cursor: default;
  background-color: $another-green;
  color: $white;
}

.admin-btn {
  @include button;
  @include big;
  border: solid 2px $red;
  color: $red;
  background-color: $white;
  &:hover{
    color: $white;
    background-color: $red-hover;
    border-color: $red-hover;
  }
}
.btn{
  border-radius: 36px;
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 125ms linear;
  &.big{
    font-size: em(30);
    padding: 16px 39px;
    line-height: 36px;
  }
  &.left{
    float: left;
    margin-right: 12px;
  }
}
.green{
  background-color: $another-green;
  color: $white;
  &:hover{
    background-color: $green;
    color: $white;
  }
}
.blue{
  background-color: $btn-blue;
  color: $white;
  &:hover{
    color: $white;
    background-color: $btn-blue-hover;
  }
}
.gray{
  background-color: $btn-gray;
  color: $white;
  &:hover{
    color: $white;
    background-color: $btn-gray-hover;
  }
}

//icons
i{
  display: inline-block;
  vertical-align: top;
  background-position: left top;
  background-repeat: no-repeat;
  transition: all 125ms linear;
  &.settings{
    width: 25px;
    height: 25px;
    padding-right: 10px;
    margin-top: -2px;
    @include inline-img($settings-gray, 23px, 23px);
  }
  &.height{
    width: 36px;
    height: 36px;
    @include inline-img($height, 36px, 36px);
    padding-right: 10px;
  }
  &.weight{
    width: 36px;
    height: 36px;
    @include inline-img($weight, 36px, 36px);
    padding-right: 10px;
  }
  &.up{
    width: 12px;
    height: 14px;
    @include inline-img($up);
    padding-right: 10px;
  }
}
a.save i.settings{
  @include inline-img($settings-blue, 23px, 23px);
}
a:hover i.settings{
  @include inline-img($settings-red, 23px, 23px);
}
