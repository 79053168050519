.person-short{
    line-height: em(16px);
  .avatar{
    display: inline-block;
    vertical-align: middle;
  }
  .info{
    height:40px;
    padding-left: 20px;
    display: inline-block;
    vertical-align: middle;

  }
  .name{
    font-weight: 500;
    clear:right;
    margin: 5px 0;
    display: block;
  }
  .date{
    font-size: em(14);
    color: $light-text;
    padding-right: 12px;
  }
}
.person{
  .avatar{
    display: inline-block;
    vertical-align: middle;
  }
}
