.launch-app {
  text-align: center;
  h1{
    line-height: 1.2;
    @include engine;
    @include breakpoint($mobile) {
      font-size: em(36px);
    }
  }
  a {
    @include engine;
    @include bigBlueButton;
    @include background-image-retina($path + 'logo_s', 'png', 48px, 60px);
    background-position: 20px 9px;
    background-repeat:no-repeat;
    margin: 50px auto 60px;
    padding-left: 84px;
    @include breakpoint(max-width 360px) {
      height: 37px;
      background-position: 15px 9px;
      padding: 16px 16px 16px 75px;
    }
  }
}
