@mixin baseFont{
  color: $text;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: $browser-context;
}

body {
  @include baseFont;
  width: 100%;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $background-page;
  @include breakpoint($tablet-desktop) {
    height: 100vh;
    padding-bottom: 84px;
    box-sizing: border-box;
    @include breakpoint($tablet) {
      padding-bottom: 158px;
    }
  }
  &.main{
    background-color: $white;
  }
}

header, nav, main, article, aside, footer {
  float: left;
}

main, footer {
  width: 100%;
}
main{
  @include breakpoint($tablet-desktop) {
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
    padding-top: 100px;
    @include breakpoint($tablet) {
      padding-top: 80px;
    }
  }
}
h1{
  font-size: em(48);
  @include breakpoint($tablet-desktop) {
    font-size: em(64);
  }
}
h2{
  font-weight: 500;
  font-size: em(24);
  @include breakpoint($tablet-desktop) {
    font-size: em(36);
  }
  &.engine{
    @include breakpoint($mobile) {
      font-size: em(50);
      line-height: 70px;
    }
    font-size: em(64);
    line-height: 77px;
  }
}

h4{
  font-size: em(28);
}
h6{
  font-size: em(14);
  @include breakpoint($tablet-desktop) {
    font-size: em(18);
  }
}
p{
  line-height: 26px;
  padding-top: 17px;
  &.helvetica.small-text{
    line-height: 22px;
  }
}
.small-text,
.footnote{
  font-size: em(14);
}

img{
  max-width: 100%;
}
a{
  cursor: pointer;
  color: inherit;
  outline: 0;
  text-decoration: none;
  outline: 0;
  transition: all 125ms linear;
  &:hover{
    color: $red-hover;
  }
}
.centered{
  text-align: center;
}
.hide{
  display: none;
}
.half{
  width: 50%;
}
.right{
  float: right;
}
.left{
  float: left;
}
.red{
  color: $red;
}
.circle-close{
  @include inline-img ($cross-white, $posLeft: center, $posTop: center);
  &:hover{
    background-color: $close-hover;
  }
}

@mixin sectionContent {
  background-color: $white;
  width: 88%;
  margin: 0 auto 25px;
  padding: 22px 6% 5px;
  border-radius: 8px;
  line-height: em(26px);
  @include breakpoint($tablet) {
    width: 82%;
    padding: 30px 5% 5px;
    margin: 36px auto 42px;
  }
  @include breakpoint($desktop) {
    width: 860px;
    padding: 30px 40px 5px;
    margin: 48px auto 30px;
  }
}
.task-content, section{
  &.content{
    background-color: $white;
    width: 88%;
    margin: 0 auto 25px;
    padding: 22px 6% 5px;
    border-radius: 8px;
    line-height: em(26px);
    @include breakpoint($tablet) {
      width: 82%;
      padding: 30px 5% 5px;
      margin: 36px auto 42px;
    }
    @include breakpoint($desktop) {
      width: 860px;
      padding: 30px 40px 5px;
      margin: 48px auto 30px;
    }
    &.transparent {
      background: none;
    }
    & h2:not(.engine){
      line-height: 30px;
      @include breakpoint($tablet-desktop) {

        line-height: 42px;
      }
    }
  }
  &.common{
    width: 88%;
    padding: 0 15px;
    margin: 0 auto;
    @include breakpoint($tablet) {
      width: 82%;
    }
    @include breakpoint($desktop) {
      width: 1170px;
    }
  }
  &.content-with-sidebar {
    max-width: 1040px;
    margin: 49px auto 32px;
    &:after {
      content: '.';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}

table{
  width: 100%;
  margin: 10px 0 16px;
  thead{
    color: $thead;
    text-align: left;
    th{
      font-family: 'HelveticaNeue-Bold', sans-serif;
      padding: 22px 0;
      position: relative;
      min-width: 100px;
      .checkbox{
        position: absolute;
        left:61px;
        top: 18px;
      }
    }
  }
  tbody{

    line-height: 22px;
    font-size: 14px;
    td{
      vertical-align: middle;
      font-family: 'HelveticaNeue', sans-serif;
      padding: 13px 0;
      position: relative;
      .checkbox{
        position: absolute;
        left:61px;
        top: 13px;
      }
    }
  }

}

@mixin popup {
  @include triangle-up ($white)
  z-index: 200;
  position: absolute;
  display: none;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.25);
}

