.icon-cat2{
  @include inline-img($category2, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat3{
  @include inline-img($category3, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat4{
  @include inline-img($category4, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat5{
  @include inline-img($category5, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat6{
  @include inline-img($category6, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat7{
  @include inline-img($category7, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat8{
  @include inline-img($category8, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat9{
  @include inline-img($category9, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}
.icon-cat10{
  @include inline-img($category10, $posLeft: center, $posTop: center, $sizeX: 32px, $sizeY: 32px);
}

.category3{
  background-color: $light-blue;
  &.btn:hover{
    background-color: $light-blue-hover;
  }
}
.category5{
  background-color: $yellow;
  &.btn:hover{
    background-color: $yellow-hover;
  }
}
.category8{
  background-color: $violet-category;
  &.btn:hover{
    background-color: $violet-category-hover;
  }
}
.category7{
  background-color: $akvamarin;
  &.btn:hover{
    background-color: $akvamarin-hover;
  }
}
.category4{
  background-color: $light-green;
  &.btn:hover{
    background-color: $light-green-hover;
  }
}
.category2{
  background-color: $pink;
  &.btn:hover{
    background-color: $pink-hover;
  }
}
.category6{
  background-color: $light-orange;
  &.btn:hover{
    background-color: $light-orange-hover;
  }
}
.category9{
  background-color: $сategory9;
  &.btn:hover{
    background-color: $сategory9-hover;
  }
}
.category10{
  background-color: $сategory10;
  &.btn:hover{
    background-color: $сategory10-hover;
  }
}


