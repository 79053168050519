//rotating play arrow (main page)
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
//show swiming header
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
//rotate and scale velo badge on new_index page
@keyframes vel {
  0% { transform: rotate(-700deg) scale(0);}
  100% {transform: rotate(0deg) scale(1);}
}

//show/hide/different popup
@keyframes show_center {
  from { top: 0; transform: translateY(-110%); margin-top: 0;}
  50% { top: 50%; transform: translateY(-50%); margin-top: 40px;}
  to { top: 50%; transform: translateY(-50%); margin-top: 0;}
}
@keyframes hide_center {
  from { top: 50%; transform: translateY(-50%); margin-top: 0;}
  50% { top: 50%; transform: translateY(-50%); margin-top: 40px;}
  to { top: 0; transform: translateY(-110%); margin-top: 0;}
}

@keyframes show_top {
  from { top: 0; transform: translateY(-110%); margin-top: 0;}
  50% { top: 0;  transform: translateY(0); margin-top: 40px;}
  to { top: 0; transform: translateY(0);  margin-top: 0;}
}
@keyframes hide_top {
  from { top: 0; transform: translateY(0); margin-top: 0;}
  50% { top: 0; transform: translateY(0); margin-top: 40px;}
  to { top: 0;  transform: translateY(-110%); margin-top: 0;}
}

//sliding animation ( top/down )
@mixin slide_top($name, $padding) {

  @keyframes slide_#{$name} {
    from { opacity: 1;}
    50% { opacity: 0; height: auto; padding: #{$padding};}
    to { opacity: 0; height: 0; padding: 0;}
  }
  animation: slide_#{$name} 600ms linear;
  animation-fill-mode: forwards;
}
@mixin slide_down($name, $padding) {

  @keyframes slide_#{$name} {
    from { opacity: 0;height: 0; padding: 0;}
    50% { opacity: 0; height: auto; padding: #{$padding};}
    to { opacity: 1;  height: auto; padding: #{$padding};}
  }
  animation: slide_#{$name} 600ms linear;
  animation-fill-mode: forwards;
}

//sliding animation ( right/left )
@keyframes slide_right {
  from {width: 0; margin-right: 0;}
  to { width: 130px; margin-right: 30px;}
}
@keyframes slide_left {
  from {opacity: 1; width: 130px; margin-right: 30px;}
  to {opacity: 0; width: 0; margin-right: 0;}
}
