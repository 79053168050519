.child-edit {
  h1 {
    @include engine;
    line-height: 1.2;
    margin: -7px 0 27px;
  }

  form {
    @include helvetica;
    color: $form-text;
    font-size: em(18px);

    p {
      display: inline-block;
      width: 580px;
      margin: 6px 0;
      padding: 0;
      label {
        width: 170px;
        padding: 15px 10px 15px 0;
        display: inline-block;
      }
    }

    .style-select,
    input[name="birthdate"] {
      opacity: 0;
    }
    .style-select.jq-selectbox {
      opacity: 1;
      width: 400px;
      margin-left: -5px;
      height: 56px;
      &.day {
        width: 96px;
      }
      &.month {
        width: 182px;
        margin-left: 7px;
      }
      &.year {
        width: 108px;
        margin-left: 7px;
      }
    }
    input[type='text'] {
      @include grayInput;
      line-height: 52px;
      padding: 2px 12px;
      width: 376px;
      margin: 0 0 0 -5px;
      font-size: 18px;
      &.units {
        margin: 0 5px 0 -5px;
        vertical-align: middle;
        width: 63px;
        color: #929292;
        height: 53px;
        background-color: $popup-footer-bg;
        padding: 3px 0 0 5px;
        border-radius: 0 4px 4px 0;
      }
    }
    .child-avatar {
      margin: 29px 0 26px;
      .avatar {
        display: inline-block;
        margin-right: 23px;
        vertical-align: middle;
      }
      input[type="file"] {
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      label {
        @include engine;
        @include bigBlueButton;
        width: 290px;
        height: 68px;
        box-sizing: border-box;
        padding: 16px;
        text-align: center;
        font-size: 30px;
        vertical-align: middle;
      }
    }
    .children-params {
      border-bottom: 1px solid $popup-footer-bg;
      margin: 0 -40px 22px;
      padding: 0 40px 42px;
      label {
        margin-right: -5px;
      }
      input[type='number'] {
        width: 63px;
        margin: 0;
        padding: 0;
        border: none;
        height: 56px;
        background-color: $popup-footer-bg;
        outline: none;
        font-size: 18px;
        color: $form-text;
        text-align: right;
        border-radius: 4px 0 0 4px;
      }
      .jq-number {
        padding: 0;
        .jq-number__spin {
          display: none;
        }
        .jq-number__field {
          border: none;
          box-shadow: none;
          width: 63px;
        }
      }
    }
    input[type='submit'] {
      @include engine;
      @include bigGreenButton;
      width: 190px;
      margin-bottom: 19px;
      &[disabled="disabled"] {
        @include bigDisabledButton;
      }
    }
    a.reset {
      @include engine;
      @include bigBlueBorderButton;
      width: 190px;
      margin-left: 12px;
      box-sizing: border-box;
      text-align: center;
    }
  }
}
