.right-sidebar {
  float: right;
  width: 220px;
  @include breakpoint ($mobile) {
    display: none;
  }
  @include breakpoint ($tablet) {
    margin: 0 16px;
  }
  h5 {
    font-size: em(18);
    font-weight: 500;
    margin: 0 0 16px;
  }
  .another-categories a{
    font-size: em(17);
    font-weight: 500;
    letter-spacing: -0.3px;
    color: $white;
    padding: 15px 0 13px;
    margin: 8px 0;
    text-align: center;
    width: 100%;
  }

  .another-reports {
    margin-bottom: 32px;
    a{
      display: block;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 16px;
      width: 218px;
      img {
        max-height: 165px;
        width: 100%;
        display: block;
      }
      .default-task-image {
        @include deault-taks-img(131px);
        height: 165px;
      }
      .report-author {
        background-color: $white;
        font-size: em(15);        
        line-height: 1.33;
        padding: 11px 16px 11px;
        box-sizing: border-box;
        border: solid 1px $border-gray;
        border-top: 0;
        border-radius: 0 0 8px 8px;
        .avatar {
          display: inline-block;
          vertical-align: top;
          margin-right: 11px;
          @include avatar(32px);
        }
        span {
          display: inline-block;
          width: 137px;
          margin-top: 7px;
          position: relative;
          overflow: hidden;
          @include lightening (59px);
        }
      }
    }
  }

}
