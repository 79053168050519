////////////////////////
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
//
////////////////////////

// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 2;
  overflow: hidden;
  position: fixed;

  background: $mfp-overlay-color;
  opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $mfp-popup-padding-left;
  box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top .mfp-container{
    &:before {
      display: none;
    }
}

// Popup content holder
.mfp-content {
  /*position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;*/
  z-index: $mfp-z-index-base + 5;
}
.mfp-inline-holder,
.mfp-ajax-holder {
  .mfp-content {
    //width: 100%;
    cursor: auto;
  }
}

// Cursors
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur {
  &, .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor {
  .mfp-content {
    cursor: auto;
  }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select:none;
  -moz-user-select: none;
  user-select: none;
}

// Hide the image during the loading
.mfp-loading {
  &.mfp-figure {
    display: none;
  }
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
  // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
  .mfp-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
} @else {
  .mfp-hide {
    //display: none !important;
  }
}
