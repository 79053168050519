@import 'login_form';
@import 'login_email';
@import 'is_it_you';

.login-wrap{
  text-align: center;
  width: 460px;
  &.white-popup{
    width: 300px;
    height: 420px;
    padding: 20px;
    box-sizing: border-box;
    @include breakpoint($tablet-desktop) {
      width: 468px;
    }
  }
  .agreement {
    font-size: 12px;
    position: absolute;
    padding: 0 20px;
    box-sizing: border-box;
    bottom: 20px;
    left: 0;
    width: 100%;
    @include breakpoint($mobile) {
      bottom: 10px;
    }
  }
  &.static .agreement{
    position: relative;
    line-height: 1.2;
    padding: 0 75px 25px;
    bottom: auto;
  }
}
