/*** second section ***/
.middle{
  position: relative;
  margin: 0 auto;
  .phone{
    width: 378px;
    height: 661;
    margin: 0 auto;

    h4{
      color: $white;
      font-size: 24px;

    }
    h2{
      color: $another-gray;
      font-weight: normal;
    }
  }


  .first-phone, .second-phone, .third-phone{
    text-align: center;
    outline: none;
    .header{
      position: absolute;
      width: 232px;
      height: 58px;
      top: 167px;
      left: 88px;
    }
    .phone-content{
      position: absolute;
      top: 225px;
      left: 88px;
      width: 232px;
      height: 294px;
    }
    .footer{
      position: absolute;
      width: 232px;
      height: 70px;
      top: 513px;
      left: 88px;
    }
  }

  .first-phone{

    .header{
      text-align: left;
      @include inline-img($bycicle, 36px, auto, 10px, 15px);
      background-color: $violet;
      h4{

        margin: 17px 0 0 57px;
      }
    }
    .phone-content{
      display: none;
      background: #fff;
      text-align: left;
      overflow: hidden;
      h4{
        font-weight: normal;
        color: $violet;
        margin: 8px 10px 24px;
        line-height: 20px;
        width: 192px;
      }
      p{
        padding: 0 10px;
        @include robotoLight;
        font-size: 16px;
        width: 203px;
        line-height: 19px;
      }
    }
    .footer{
      display: none;
      background: $green;
      h4{
        margin: 20px 0;
      }
    }
  }

  .second-phone{
    .boy2{
      width: 100%;
      height: 100%;
      background: left 102px no-repeat;
      @include background-image-retina($path + 'boy2', 'png', 378px, 459px);
    }
    .phone-content{
      background: -30px -23px no-repeat;
      @include background-image-retina($path + 'boy21', 'png', 258px, 403px);
    }
    .header{
      background: $black;

    }
    .footer{
      background: left top no-repeat;
      @include background-image-retina($path + 'ppc', 'png', 232px, 71px);
    }
    .circle{
      position: absolute;
      top:521px;
      left:176px;
      width: 55px;
      height: 55px;
      background: $white;
      border-radius: 27px;
    }
  }

  .third-phone{
    .header{
      background: $white;
      h4{
        color: $violet;
        margin: 17px 0;
      }
    }
    .phone-content{
      background: 40px 250px no-repeat, $white;
      @include background-image-retina($path + 'third-footer', 'png', 163px, 23px);
      .vel{
        width: 140px;
        height: 140px;
        background: left top no-repeat, $white;
        @include background-image-retina($path + 'vel', 'png', 140px, auto);
        display: inline-block;
        margin-top: 23px;
        z-index: 0;
        position: relative;
      }
      .vel.rotate{
        animation: rotating 100ms ease;
      }
      h4{
        color: $black;
        font-size: 36px;
        font-weight: normal;
        margin: 0;
        line-height: 20px;
        z-index: 1;
        position: relative;
      }
    }
    .footer{
      background: $green;
      h4{
        margin: 20px 0;
      }
    }
  }


}

/* mobile */
@include breakpoint($mobile) {
  .middle{
    position: relative;
    margin-top:53px;
    min-width: 300px;
    width: 80%;
    margin-bottom: 36px;
    h2{
      margin: 0;
      padding: 0;
      font-size:30px;
      line-height: 28px;
    }
    .phone{

      width: 300px;


    }
    .nav{
      display: none;
    }

    .video{
      width: 48px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      position: absolute;
      width: 100%;
      top: -53px;
      left: 0;
      .play{
        display: inline-block;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        background-size: 48px;
        width: 48px;
        height: 48px;
      }
      a{
        display: none;
      }
    }

    .first-phone, .second-phone, .third-phone{
      position: relative;
      height: 494px;
      .header{
        top: 128px;
        left: 64px;
        height: 43px;
        width: 172px;
      }
      .phone-content{
        top: 171px;
        left: 64px;
        height: 218px;
        width: 172px;
      }
      .footer{
        top: 391px;
        left: 64px;
        height: 52px;
        width: 172px;
        background-size: 172px auto;
      }
    }
    .first-phone{
      .footer h4{
        margin: 8px 0;
      }
      .header{
        text-align: center;
        background-size: 30px auto;
        background-position: 15px 10px;
        h4{
          margin: 9px 0 0;
        }
      }
      .phone-content{
        h4{
          width: 162px;
          margin: 3px 5px 0 8px;
        }
        p{
          width: 162px;
          padding: 0 5px 0 8px;
          font-size: 14px;
        }
      }
    }

    .second-phone{
      .circle{
        width: 41px;
        height: 41px;
        border-radius: 21px;
        top: 396px;
        left: 130px;
        display: none;
      }
      .boy2{
        background-position: center 83px;
        z-index: -1;
        background-size: 280px auto;
      }
      .phone-content{
        display: none;
      }

    }
    .third-phone{
      h4{
        margin: 8px 0;
      }
      .phone-content{
        background-position: 20px 180px;
        background-size: 140px auto;
        .vel{
          width: 90px;
          height: 90px;
          background-size: 90px auto;
          margin-top: 18px;
        }
        h4{
          font-size: 30px;
        }
      }
      .footer{
        h4{
          margin: 10px;
        }
      }
    }

    .phone:after{
      position: absolute;
      height: 565px;
      width: 278px;
      content: '';
      @include inline-img($phone, 206px, auto);
      pointer-events: none;
      top: 81px;
      left: 47px;
    }

    .right-arr{
      position: absolute;
      right: 10px;
      top: 263px;
      cursor: pointer;
      height: 42px;
      width: 24px;
      @include inline-img($left);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .right-arr:hover{
      @include inline-img($left-pressed);
    }
    .left-arr{
      position: absolute;
      left: 10px;
      top: 263px;
      cursor: pointer;
      height: 42px;
      width: 24px;
      @include inline-img($left);
      transform: rotate(180deg);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .left-arr:hover{
      @include inline-img($left-pressed);
    }
    @media only screen
      and (min-width: 450px){
      .left-arr{
        left: 5%;
      }
      .right-arr{
        right: 5%;
      }
    }
    @media only screen
      and (min-width: 500px){
      .left-arr{
        left: 10%;
      }
      .right-arr{
        right: 10%;
      }
    }

    @media only screen
      and (min-width: 600px){
      .left-arr{
        left: 15%;
      }
      .right-arr{
        right: 15%;
      }
    }
    @media only screen
      and (min-width: 650px){
      .left-arr{
        left: 20%;
      }
      .right-arr{
        right: 20%;
      }
    }

  }
}

/* tablet */
@include breakpoint($tablet) {
  .middle{
    min-width: 700px;
    width: 80%;
    .phone{
      width: 636px;
      height: 763px;
    }
    .phone:before{
      position: absolute;
      height: 765px;
      width: 433px;
      content: '';
      top: 258px;
      right: 31px;
      z-index: 1;
      background: left top no-repeat;
      pointer-events: none;
      @include background-image-retina($path + 'hand', 'png', 433px, 768px);
    }
    .phone:after{
      position: absolute;
      height: 565px;
      width: 278px;
      left: 180px;
      top: 158px;
      content: '';
      z-index: 2;
      @include inline-img($phone, 278px, auto);
      pointer-events: none;
    }
    .first-phone, .second-phone, .third-phone{
      position: relative;
      height: 873px;
      .header{
        left: 202px;
        top: 229px;
      }
      .phone-content{
        left: 202px;
        top:287px;
      }
      .footer{
        left: 202px;
        top: 580px;
      }
    }
    .second-phone{
      .boy2{
        background-position: center 50px;
        z-index: -1;
      }
      .circle{
        display: none;
        left: 291px;
        top: 588px;
      }
      .phone-content{
        background-position: -15px -29px;
      }
    }

    h2{
      font-size: 48px;
      margin: 21px 31px 47px;
      line-height: 58px;
    }
    .nav{ /*.nav*/
      position: absolute;
      top: 318px;
      right: 0;
      width: 70px;
      height: 240px;
      div{ /*div*/
        list-style: none;
        @include inline-img($page-circle, 70px, auto);
        width: 70px;
        height: 62px;
        margin: 0 0 11px ;
        padding-top: 15px;
        cursor: pointer;
        font-size: 40px;
        color: $white;
        @include robotoBold;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      }
      div.active{ /*.div.active*/
        @include inline-img($page-circle-hover, 70px, auto);
      }
    }
    .video{
      width: 118px;
      text-align: center;
      position: absolute;
      left: 43px;
      top: 372px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      .play{
        display: inline-block;
        margin-bottom: 13px;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      a{
        font-size: 24px;
        text-decoration: none;
        color: $blue;
      }
    }
    @media only screen
      and (min-width: 950px){

      .nav{
        right: 5%;
      }
    }
    @media only screen
      and (min-width: 1000px){
      .video{
        left: 5%;
      }

    }
    @media only screen
      and (min-width: 1100px){
      .video{
        left: 10%;
      }
      .nav{
        right: 10%;
      }

    }

  }
}

/* desktop */
@include breakpoint($desktop) {
.second{
  .middle{
    width: 1200px;
    padding-top: 72px;
    .phone{
      width: 1200px;
      overflow: visible;
    }
    .video, .nav{
      display: none;
    }
    .third-phone:before{
      position: absolute;
      height: 763px;
      width: 433px;
      content: '';
      bottom: -289px;
      right: -95px;
      z-index: -1;
      background: left top no-repeat;
      @include background-image-retina($path + 'hand', 'png', 433px, 768px);
      transform: rotate(-5deg);
    }
    .first-phone:after, .second-phone:after, .third-phone:after{
      position: absolute;
      height: 565px;
      width: 278px;
      top: 96px;
      left: 61px;
      content: '';
      @include inline-img($phone, 278px, auto);
      pointer-events: none;
    }
    .first-phone, .second-phone, .third-phone{
      position: relative;
      width: 400px;
      height: 661px;
      float: left;
      display: inline-block;
      .footer, .header{
        display: none;
      }
    }
    .first-phone, .third-phone, .second-phone{
      .phone-content, {
        display: none;
      }
    }
    .second-phone{
       .boy2{
        opacity: 0;
        position: relative;
        top: 0;
        left: -80px;
      }
    }

    .third-phone{
      h2:before{
        content: '3';
      }
      margin-top: 22px;
      transform: rotate(5deg);
      .vel{
        animation: vel 300ms linear;
      }
    }
    .first-phone{
      h2:before{
        content: '1';
      }
      margin-top: 22px;
      transform: rotate(-5deg);
    }
    h2{
      font-size: 32px;
      margin: 0 20px 0 70px;
      position: relative;
      text-align: left;
      line-height: 38px;
    }
    h2:before{
      font-size: 48px;
      position: absolute;
      @include robotoBold;
      color: $white;
      @include inline-img($page-circle);
      width: 58px;
      height: 73px;
      left: -70px;
      top: -20px;
      content: '2';
      padding: 22px 0 0 25px;
      z-index: -1;
    }
  }
}
}
