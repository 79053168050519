@import "../../../../default/scss/colors";

section.tasks-control.category-description {
  .text-description {
    padding-top: 17px;
    a {
      color: $btn-blue;
      border-bottom: 1px solid rgba($btn-blue, 0.5);
      &:hover {
        color: $red-hover;
        border-bottom: 1px solid rgba($red-hover, 0.5);
      }
    }
  }

  .breadcrumbs {
    margin: 0;
    margin-bottom: 20px;
    ul {
      display: block;
    }
  }
}
