.task-page {
  max-width: 640px;
  min-width: 320px;
  margin: 0 236px 16px 150px;
  border-radius: 12px;
  background-color: $white;
  overflow: hidden;
  padding: 20px;
  @include breakpoint($mobile) {
    margin: 0;
    max-width: none;
    border-radius: 0px;
  }
  @include breakpoint($tablet) {
    margin-left: 16px;
    max-width: none;
  }
  .task-header {
    min-height: 366px;
    position: relative;
    overflow: hidden;
    padding: 18px 20px ;
    margin: -20px -20px 8px;
    box-sizing: border-box;
    text-align: center;
    @include breakpoint($mobile-tablet-portrait) {
      min-height: auto;
      margin: -20px -20px 0;
      padding: 18px 20px 1px;
    }
    .task-cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        left:0;
        top:0;
      }
      &.empty {
        height: 100%;
        background-color: $default-task-bg;
      }
      img {
        max-width: none;
        width: 100%;
        height: auto;
      }
    }
    .favorite {
      position: relative;
      float: right;
      vertical-align: baseline;
      font-size: em(13);
      height: 25px;
      overflow: visible;
      span {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 6px 12px;
        color: $white;
        border-radius: 14px;
        display: inline-block;
        letter-spacing: -0.1px;
        margin-right: 9px;
        transition: all 125ms linear;
       }
      .star {
        display: inline-block;
      }
      &:hover {
        span {
          font-size: 15px;
        }
      }
    }
    .task-header-content {
      display: inline-block;
      position: relative;
      width: 100%;
      color: $white;
      .main-category {
        display: inline-block;
        width: 152px;
        height: 152px;
        border-radius: 76px;
        margin-top: 28px;
        line-height: 152px;
        background-size: 72px 72px;
        @include breakpoint($mobile) {
          width: 60px;
          height: 60px;
          background-size: 32px 32px;
        }
      }
      h1 {
        font-size: em(36);
        font-weight: 500;
        line-height: 42px;
        margin-top: 8px;
        @include breakpoint($mobile-tablet-portrait) {
          font-size: em(30);
          line-height: 36px;
          margin-bottom: 18px;
          min-height: 72px;
        }
      }
    }
    .task-description {
      color: $white;
      display: inline-block;
      position: relative;
      width: 100%;
      @include breakpoint($mobile-tablet-portrait) {
        text-align: left;
        margin: 0px -20px -18px;
        padding: 25px 20px 0;
        background-color: $white;
        color: $dark-gray;
      }
      p {
        max-width: 480px;
        padding: 0;
        margin: auto;
        line-height: 21px;
        font-size: em(18);
        @include breakpoint($mobile-tablet-portrait) {
          margin: 0;
        }
      }
      .task-period {
        font-weight: 500;
        line-height: 21px;
        font-size: em(18);
      }

    }

  }

  .task-tags a {
      @include blueButton;
      font-size: em(13);
      letter-spacing: -0.1px;
      padding: 6px 12px;
      margin: 8px 2px;
      line-height: 15px;
      border-radius: 14px;
      &:first-child {
        margin-left: 0;
      }
  }

  .task-body {
    font-size: em(16);
    line-height: 25px;
    overflow: hidden;
    ul, ol {
      margin-left: 25px;
      li {
        padding-left: 10px;
      }
    }
    ol {
      list-style: decimal;
    }
    ul {
      list-style: disc;
    }

    .alert {
      margin: 12px 0;
      padding: 21px 16px;
      box-sizing: border-box;
      background-color: $alert-bg;
      border-radius: 8px;
      font-weight: 500;
      color: $white;
    }
    .task-content-video,
    .task-content-image {
      margin: 24px auto;
      .image-container {
        text-align:center;
      }
    }
    .btn {
      @include bigBlueButton;
      @include engine;
      margin-top: 15px;
    }

  }

  .footer-login {
    background-color: $footer-login-bg;
    margin: 33px -20px -20px;
    width: auto;
    padding-top: 32px;
  }
}

section.another-tasks {
  //width: 100%;
  min-width: 320px;
  @include breakpoint($tablet) {
    width: 100%;
  }
  .another-tasks-list {
    margin-left: -15px;
    margin-right: -15px;
    @include breakpoint($tablet) {
      width: 820px;
      margin: auto;
    }
    @include breakpoint($mobile-tablet-portrait) {
      width: 710px;
      margin: auto;
    }
    @include breakpoint($mobile) {
      width: 100%;
      margin: auto;
    }
    li {
      width: 365px;
      @include breakpoint($mobile-tablet-portrait) {
        width: 315px;
      }
      @include breakpoint($mobile) {
        width: 100%;
      }
    }
  }
  >h2 {
    @include engine;
    font-size: em(48);
    padding: 0 7px 12px;
    @include breakpoint($mobile) {
      font-size: em(32);
    }
  }
}
