section.content.error{
  @include breakpoint($desktop) {
    width: 951px;
  }
  h1{
    font-size: 48px;
    &.obelix{
      font-size: 40px;
      line-height: 1.5em;
      margin-bottom: 10px;
      @include breakpoint($mobile-tablet) {
        font-size: 30px;
      }
    }
  }
  p{
    font-size: 21px;
  }
  .static.login-wrap{
    margin: 60px auto 0;
    position: relative;
    @include breakpoint($mobile) {
      width: 100%;
      max-width: 400px;
    }
  }
  img{
    padding-top: 75px;
  }
  .guards {
    z-index: -1;
    position: relative;
    height: 158px;
    width: 100%;
    margin: 0 auto;
    max-width: 832px;
    background: center top no-repeat;
    @include background-image-retina($path + 'errors/kids-401-bottom', 'png', 100%, auto);
    &:before {

      background: center bottom no-repeat;
      @include background-image-retina($path + 'errors/kids-401-top', 'png', 100%, auto);
      content: '';
      width: 100%;
      height: 281px;
      left: 0;
      bottom: 100%;
      position: absolute;

    }
  }
}
@include breakpoint($tablet-desktop) {
  .main .error{
    margin-top: 0px;
  }
}
