/* breakpoints */
$mobile: max-width 767px;
$mobile-tablet: max-width 1199px;
$tablet: 768px 1199px;
$desktop: 1200px;
$tablet-desktop: 768px;
$mobile-tablet-portrait: max-width 840px;
$mobile-portrait: (max-width 767px) (orientation portrait);

$medium_range: 600px 939px;

/*default font-size*/
$browser-context: 16px;

/* path for images in css files*/
$path: '/themes/new-design/img/';
$font-path: '/themes/new-design/fonts/';




