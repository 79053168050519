.children-list {
  width: 102px;
  text-align: center;
  @include breakpoint ($tablet) {
    margin: 0 16px;
  }
  @include breakpoint ($mobile-tablet-portrait) {
    display: none;
  }

  .another-child {
    .avatar {
      @include avatar(64px);
      margin: 12px auto;
    }
  }
  
  .current-child {
    font-size: em(18);
    font-weight: 500;
    span {
      margin: 2px auto 32px;
      display: block;
      font-weight: 300;
      font-size: 14px;
    }
    .avatar {
      @include avatar(102px);
      margin-bottom: 8px;
    }
  }
  .more-children {
    display: block;
    margin-top: 32px;
    color: $btn-blue;
    font-weight: 500;
    &:hover {
      color: $red-hover;
    }
    span{
      display: block;
      margin-top: 5px;
    }
  }
}
