.share-popup{
  overflow: hidden;
  .make-public{
    max-width: 640px;
    .show-public{
      width: 142px;
      @include bigBlueButton();
      @include engine();
    }
    img {
      margin: 32px;
    }
  }
  .already-public{
    max-width: 640px;
    float: left;
    input{
      @include greenInput();
      font-family: Roboto;
      font-size: 18px;
      line-height: 1.22;
      padding: 23px 12px;
      width: 490px;
      margin: 32px 0 32px 20px;
      float: left;
      display: inline-block;
      outline: none;
    }
    .copy{
      float: left;
      display: inline-block;
      width: 68px;
      height: 68px;
      margin: 32px 0;
      border-radius: 36px;
      margin-left: 16px;
      @include inline-img($copy, $posLeft: center, $posTop: center);
      background-color: $btn-gray;
      &:hover{
        background-color: $btn-gray-hover;
        border-color: $btn-gray-hover;
      }
    }
    .social_share_buttons{
      margin: 0;
    }
  }
  h3{
    @include engine();
    font-size: em(32px);
    margin: 32px 20px 10px;
  }
  p{
    line-height: 22px;
    text-align: left;
    margin: 0 20px;
  }
  .footer{
    float: left;
    width: 100%;
    padding: 24px 0;
    background-color: $popup-footer-bg;
  }
}
