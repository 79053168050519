main.centred-content {
  display: table;
}

.not-logged-user {
  width: 100%;
  min-width: 320px;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  .user-avatar {
    @include avatar(152px);
    margin: 0 auto;
  }
  h1 {
    font-size: em(36);
    font-weight: 500;
    line-height: 42px;
    margin-top: 15px;
  }
  .level {
    font-size: em(18);
    font-weight: 300;
    padding: 0;
  }
}

.user-info {
  @include human-info(user);
  .edit-user{
    margin-top: 24px;
    &.admin {
      margin-bottom: 15px;
    }
  }
  .label.premium {
    font-size: em(16);
    background-color: $gold;
    border-radius: 8px;
    padding: 8px 16px 7px;
    margin-bottom: -2px;
    display: inline-block;
  }
  h1 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: em(36);
    font-weight: 500;
  }
  .roles {
    width: 100px;
    display: inline-block;
    vertical-align: middle;
    .checkbox label:first-child {
        padding-left: 25px;
        vertical-align: bottom;
        line-height: 22px;
    }
  }
  .send-roles{
    @include bigBlueButton;
    @include engine;
    font-size: em(20);
    padding: 0 15px;
    vertical-align: middle;
  }
}

//margin-top ???
.my-children {
  @include profileContent;
  h2{
    @include engine;
    font-size: em(48);
    margin-top: -6px;
    line-height: 48px;
  }
  .my-children-list {
    float: left;
    margin-bottom: 35px;
    margin-right: -25px;
    a {
      display:block;
      float: left;
      width: 152px;
      vertical-align: top;
      margin: 24px 25px 0 0 ;
      text-align: center;
      text-decoration: none;
      font-size: 18px;
      line-height: 1.2;
      &.add {
        @include dotted-add-circle;
      }
      &.have-invite {
        .invite-img {
          background-color: $another-green;
          color: $white;
          width: 150px;
          height: 150px;
          border-radius: 76px;
          line-height: 150px;
          vertical-align: middle;
          font-size: em(38);
        }
        &:hover {
          color: $green-hover;
          .invite-img {
            background-color: $green-hover;
          }
        }
      }
      .avatar {
        @include avatar(152px);
      }
      span {
        display: block;
        padding-top: 16px;
      }
    }
  }
}
section.badges {
  @include profileContent;
  h2 {
    @include engine;
    font-size: em(48);
    width: 50%;
    float: left;
    margin-top: -6px;
    line-height: 48px;
  }
  .all-badges {
    @include engine;
    color: $btn-blue;
    font-size: em(30);
    margin: 2px;
    &:hover {
      color: $red-hover;
    }
  }
  .badges-list {
    float: left;
    margin: 24px -12px 19px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
      a{
        display: inline-block;
        width: 152px;
        text-align: center;
        margin: 0 12px 14px;
        line-height: 19px;
        img{
          width: 152px;
          margin-bottom: 13px;
        }
      }
      .hide {
        display: none;
      }
  }
}

