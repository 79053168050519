@mixin human-info($name) {
  width: 940px;
  margin: 64px auto 14px;
  & > div{
    display: inline-block;
  }
  .avatar {
    input[type="file"] {
      visibility: hidden;
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    label {
      cursor: pointer;
      width: 256px;
      height: 256px;
      display: block;
    }
  }
  div.#{$name}-text{
    vertical-align: top;
    margin-left: 76px;
    max-width: 600px;
    h2{
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .edit-#{$name}{
      @include engine;
      @include bigBlueBorderButton;
      float: left;
      padding: 14px 35px;
      margin-right: 15px;
    }
  }
}
