////////////////////////
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader {
  color: $mfp-controls-text-color;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: $mfp-z-index-base + 4;
  a {
    color: $mfp-controls-text-color;
    &:hover {
      color: $mfp-controls-text-color-hover;
    }
  }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
  .mfp-preloader {
    display: none;
  }
}

// Hide content when it was not loaded
.mfp-s-error {
  .mfp-content {
    display: none;
  }
}

// CSS-reset for buttons
button {
  &.mfp-close,
  &.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: $mfp-z-index-base + 6;
    box-shadow: none;
    touch-action: manipulation;
  }
  &::-moz-focus-inner {
      padding: 0;
      border: 0
  }
}


// Close icon
.mfp-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 22px;
  height: 21px;
  cursor: pointer;
  @include inline-img($cross, $posLeft: center, $posTop: center);
  &.white {
    @include inline-img($cross-white, $posLeft: center, $posTop: center);
  }
  &:hover,
  &:focus {
    @include inline-img($cross-red, $posLeft: center, $posTop: center);
  }
}
.mfp-close-btn-in {
  .mfp-close {
    color: $mfp-inner-close-icon-color;
  }
}
.mfp-image-holder,
.mfp-iframe-holder {
  .mfp-close {
    color: $mfp-controls-color;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
}
div.mfp-close{
  display: none;
}

// "1 of X" counter
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: $mfp-controls-text-color;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

// Navigation arrows
@if $mfp-include-arrows {
  .mfp-arrow {
    position: absolute;
    opacity: $mfp-controls-opacity;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:active {
      margin-top: -54px;
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 35px;
      margin-left: 35px;
      border: medium inset transparent;
    }

    &:after {

      border-top-width: 13px;
      border-bottom-width: 13px;
      top:8px;
    }

    &:before {
      border-top-width: 21px;
      border-bottom-width: 21px;
      opacity: 0.7;
    }

  }

  .mfp-arrow-left {
    left: 0;
    &:after {
      border-right: 17px solid $mfp-controls-color;
      margin-left: 31px;
    }
    &:before {
      margin-left: 25px;
      border-right: 27px solid $mfp-controls-border-color;
    }
  }

  .mfp-arrow-right {
    right: 0;
    &:after {
      border-left: 17px solid $mfp-controls-color;
      margin-left: 39px
    }
    &:before {
      border-left: 27px solid $mfp-controls-border-color;
    }
  }
}



// Iframe content type
@if $mfp-include-iframe-type {
  .mfp-iframe-holder {
    padding-top: $mfp-iframe-padding-top;
    padding-bottom: $mfp-iframe-padding-top;
    .mfp-content {
      line-height: 0;
      width: 100%;
      max-width: $mfp-iframe-max-width;
    }
    .mfp-close {
      top: -40px;
    }
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: $mfp-iframe-ratio * 100%;
    iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $mfp-shadow;
      background: $mfp-iframe-background;
    }
  }
}



// Image content type
@if $mfp-include-image-type {

  /* Main image in popup */
  img {
    &.mfp-img {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      line-height: 0;
      box-sizing: border-box;
      padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
      margin: 0 auto;
    }
  }

  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: $mfp-image-padding-top;
      bottom: $mfp-image-padding-bottom;
      display: block;
      right: 0;
      width: auto;
      height: auto;
      z-index: -1;
      box-shadow: $mfp-shadow;
      background: $mfp-image-background;
    }
    small {
      color: $mfp-caption-subtitle-color;
      display: block;
      font-size: 12px;
      line-height: 14px;
    }
    figure {
      margin: 0;
    }
  }
  .mfp-bottom-bar {
    margin-top: -$mfp-image-padding-bottom + 4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    line-height: 18px;
    color: $mfp-caption-title-color;
    word-wrap: break-word;
    padding-right: 36px; // leave some space for counter at right side
  }

  .mfp-image-holder {
    .mfp-content {
      max-width: 100%;
    }
  }

  .mfp-gallery {
    .mfp-image-holder {
      .mfp-figure {
        cursor: pointer;
      }
    }
  }


  @if $mfp-include-mobile-layout-for-image {
    @media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px) {
      /**
       * Remove all paddings around the image on small screen
       */
      .mfp-img-mobile {
        .mfp-image-holder {
          padding-left: 0;
          padding-right: 0;
        }
        img {
          &.mfp-img {
            padding: 0;
          }
        }
        .mfp-figure {
          // The shadow behind the image
          &:after {
            top: 0;
            bottom: 0;
          }
          small {
            display: inline;
            margin-left: 5px;
          }
        }
        .mfp-bottom-bar {
          background: rgba(0,0,0,0.6);
          bottom: 0;
          margin: 0;
          top: auto;
          padding: 3px 5px;
          position: fixed;
          box-sizing: border-box;
          &:empty {
            padding: 0;
          }
        }
        .mfp-counter {
          right: 5px;
          top: 3px;
        }
        .mfp-close {
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          line-height: 35px;
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}

// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: $mfp-popup-padding-left-mobile;
    padding-right: $mfp-popup-padding-left-mobile;
  }
}
