//short notification list - popup
.open-notifications {
  position: relative;
  a > span {
    position: relative;
  }
  .new {
    color: $white;
    background-color: $pink-label;
    position: absolute;
    left: 100%;
    bottom: 100%;
    display: block;
    font-size: em(12px);
    padding: 1px 4px 1px 5px;
    border-radius: 94px;
    letter-spacing: 1px;
    line-height: 14px;
  }

  .notification-popup {
    display: none;
    left: 50%;
    margin-left: -240px;
    width: 480px;
    @include popup;
    .content {
      max-height: 333px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      position: relative;
      display: block;
      width: 100%;
      .notification_item {
        padding: 7px 16px 9px;
        box-sizing: border-box;
      }
    }
    .footer {
      text-align: center;
      border-top: 1px solid $btn-gray;
      a {
        padding: 17px;
        display: inline-block;
        font-weight: 500;
        color: $btn-blue;
        &:hover {
          color: $red-hover;
        }
      }
    }
  }
}

//common parts of notisications lists
.notification-popup,
.full-notification-list {
  .content {
    p {
      padding: 17px;
      text-align: center;
    }
    .notification_item {
      cursor: pointer;
      display: inline-block;
      width: 100%;
      a{
        color: $text;
      }
      &:hover {
        background-color: $popup-footer-bg;
        color: $dark-gray;
      }
      &.unreaded .notification_title::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: $pink-label;
        margin-left: 8px;
        vertical-align: middle;
      }
      .notification_title {
        font-weight: 500;
        margin: 11px 0 4px;
      }
      .notification_img {
        float: left;
        width: 64px;
        height: 64px;
        margin-right: 16px;
        overflow: hidden;
        border-radius: 32px;
        img{
          width: 64px;
          height: 64px;
        }
        &.default {
          border: 1px solid $btn-gray;
          img {
            margin: 9px 0 0 9px;
            width: 45px;
            height: 55px;
          }
        }
      }
      .notification_content {
        color: $light-text;
        font-size: em(14px);
        line-height: 1.2;
      }
    }
  }
}

//full notifications list - on page
.notifications-list {
  h2 {
    width: 50%;
    display: inline-block;
    margin-top: -7px;
  }
  .unread-all {
    @include engine;
    @include bigBlueButton;
    float: right;
    margin: 4px 0 12px;
  }
  .full-notification-list {
    margin-bottom: 48px;
    line-height: 1.2;
    .content {
      .notification_item {
        padding: 8px 24px;
        margin: 0 0 0 -24px;
        border-radius: 8px;
        .time {
          float: right;
          color: $light-text;
          font-weight: 300;
          font-size: em(14px);
          margin-top: 10px;
        }
      }
    }
  }
}




