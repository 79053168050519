/*checkbox on*/
@keyframes checkon {
  0% {background-color: $another-green;}
  26%{background-color: $another-green;}
  53%{transform: scale(0);}
  68%{background-color: transparent;}
  94%{transform: scale(1.1);}
  97%{transform: scale(1.1);}
  100%{transform: scale(1);}
}
/*checkbox on*/
@keyframes checkoff {
  0% {background-color: $another-green; transform:scale(1);}
  47%{background-color: $another-green; transform: scale(0); }
  74%{background: none; transform: scale(0); }
  100% {background: $btn-gray; transform:scale(1);}
}

.checkbox {
  width: 22px;
  height: 22px;
  background:  $btn-gray;
  position: relative;
  border-radius: 11px;
  overflow: visible;
  &.hover {
    background-color: $checkbox-bg-hover;
  }
  &:active {
    //background-color: $another-green;
  }
  input[type="checkbox"] {
    visibility: hidden;
    & + label {
      background-color: $btn-gray;
      background-size: 22px 22px;
      width: 22px;
      height: 22px;
      border-radius: 11px;
      display: block;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      overflow: visible;
      animation: checkoff 680ms ease-in-out;
    }
    &:hover + label {
      background-color: $checkbox-bg-hover;
    }
    &:active + label {
      background-color: $another-green;
    }
    &:checked + label {
      @include inline-img($tick, 17px, 12px, 4px, 4px);
      animation: checkon 680ms ease-in-out;
      &:hover {
        background-color: $checkbox-bg-hover;
      }
      &:active{
        background-color: $another-green;
      }
    }
    &.active + label {
      background-color: $another-green;
      animation: none;
    }
  }
}

/*radio on*/
@keyframes radion {
  0% {transform: scale(1.833);}
  53%{transform: scale(0);}
  94%{transform: scale(1.1);}
  97%{transform: scale(1.1);}
  100%{transform: scale(1);}
}

@mixin radioBtn() {
  position: relative;

  input[type=radio]{
    position: absolute;
    visibility: hidden;
    &:checked ~ .check::before{
      animation: radion 480ms ease-in-out;
      background-color: $radio-blue;
    }
  }
  label{
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 38px;
    z-index: 6;
    line-height: 1;
  }
  .check{
    display: block;
    position: absolute;
    border-radius: 11px;
    height: 22px;
    width: 22px;
    top: -5px;
    left: 0;
    z-index: 5;
    background-color: $btn-gray;
    cursor: pointer;
    &::before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 6px;
      height: 12px;
      width: 12px;
      top: 5px;
      left: 5px;
      margin: auto;
    }
  }
  &:hover .check {
    background-color: $btn-gray-hover;
  }
  &:active .check{
    background-color: $radio-blue;
  }
}

@mixin grayInput() {
  @include helvetica;
  border-radius: 4px;
  border: none;
  background-color: $popup-footer-bg;
  outline: none;
  color: $form-text;
  &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px $popup-footer-bg inset;
  }
}
@mixin greenInput() {
  border: none;
  color: #333;
  border-radius: 4px;
  background-color: #deffc9;
}

@mixin borderedTextarea() {
  width: 100%;
  border: none;
  resize: none;
  outline:none;
  border-bottom: 1px solid $light-gray;
  @include robotoLight;
  line-height: 1.63;
  color: $light-text;
  font-size: em(16px);
  padding: 20px 16px;
  height: 26px;
}
