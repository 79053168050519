.user-edit {
  h1 {
    @include engine;
    line-height: 1.2;
    margin: -7px 0 33px;
  }
  h5 {
    @include engine;
    font-size: em(32px);
    line-height: 1.1;
    margin: 9px 0 27px;
  }
  .fb-token {
    textarea {
      vertical-align: middle;
      border-color: $green;
      width: 394px;
    }
    strong {
      width: 175px;
      color: $green;
      display: inline-block;
    }
  }
  fieldset {
    @include helvetica;
    margin: 0 -40px 22px -40px;
    padding: 0 40px 2px;
    border-bottom: 1px solid $popup-footer-bg;
    font-size: em(18px);

    input[type="text"] {
      @include grayInput;
      line-height: 52px;
      padding: 2px 12px;
      font-size: 18px;
      width: 376px;
      height: 52px;
    }
    .email-block {
      .email {
        position: relative;
        display: inline-block;
        width: 400px;
        input {
          padding-right: 40px;
          width: 348px;
        }
      }
      .validate-email {
        @include engine;
        @include bigBlueButton;
        font-size: 30px;
        width: 290px;
        height: 68px;
        margin: 2px 0 3px;
        &:disabled {
          @include bigDisabledButton;
        }
      }
      &.default, &.error {
        .email::after {
          display: block;
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          right: 8px;
          top: 17px;
          @include inline-img($atention-red-fill, 24px, 24px);
        }
      }
      &.default .email-confirm {
        display: inline-block;
      }
      &.error .email-confirm {
        display: none;
      }
      &.success {
        .email::after {
          display: block;
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          right: 8px;
          top: 17px;
          @include inline-img($tick, 24px, 24px);
        }
        .email-confirm {
          display: none;
        }
      }
      &.empty {
        .email-confirm {
          display: none;
        }
        .email::after {
          display: none;
        }
      }
      .code-active {
        display: none;
      }
    }

    .row {
      margin: 15px 0;
      display: inline-block;
      width: 100%;
      &.hide {
        display: none;
      }
      label {
        width: 175px;
        display: inline-block;
      }
      &.email-input {
        margin-top: 0;
      }
      span {
        display: inline-block;
        width: 160px;
        margin-right: 15px;
        vertical-align: top;
      }
      .radio {
        display: inline-block;
        width: 155px;
        @include radioBtn();
        label {
          width: auto;
        }
      }
    }

    .notifications {
      @include engine;
      @include bigBlueButton;
      width: 290px;
      height: 68px;
      padding: 16px 0;
      box-sizing: border-box;
      font-size: 30px;
      margin: -3px 0 30px;
      text-align: center;
    }
    &.user-info {
      padding-bottom: 26px;
      .row{
        margin: 6px 0;
      }
    }
    .intro {
      width: 600px;
      font-size: 16px;
      line-height: 1.5;
      padding: 17px 0;
    }
    .user-avatar {
      margin: 27px 0 22px;
      .avatar {
        display: inline-block;
        margin-right: 115px;
        vertical-align: middle;
      }
      input[type="file"] {
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      label {
        @include engine;
        @include bigBlueButton;
        width: 290px;
        height: 68px;
        box-sizing: border-box;
        padding: 16px;
        text-align: center;
        font-size: 30px;
        vertical-align: middle;
        &.disabled {
          @include bigDisabledButton;
          font-size: 30px;
        }
      }
    }
    .user-social-avatar {
      position: relative;
      margin-top: 15px;
      .avatar {
        @include avatar(75px);
        display: inline-block;
        margin-right: 98px;
        vertical-align: top;
      }
      .disconect {
        @include engine;
        @include bigGrayButton;
        font-size: 30px;
      }
      .user-social {
        width: 22px;
        height: 22px;
        position: absolute;
        top: -3px;
        left: 53px;
        border-radius: 13px;
        border: white solid 2px;
        color: white;
        &.sms {
          @include engine;
          font-size: 12px;
          background-color: $another-green;
          line-height: 19px;
          font-size: 20px;
          width: 25px;
          padding-left: 5px;
        }
      }
    }
  }
  .social_login_buttons {
    text-align: left;
    margin: 0 0 19px;
  }
  .include-sms {
    @include engine;
    @include bigGreenButton;
    vertical-align: top;
    font-size: 30px;
    padding: 16px;
    text-transform: uppercase;
  }
  input[type="submit"] {
    @include engine;
    @include bigGreenButton;
    margin-bottom: 19px;
    &:disabled {
      @include bigDisabledButton;
    }
  }
}
