.email-login{
  display: none;
  .description{
    margin-top: 52px;
    font-size: em(13px);
    height: 75px;
    @include robotoLight;
    @include breakpoint($mobile) {
      margin-top: 32px;
    }
    p {
      padding: 0;
      line-height: 1.2;
    }
    .error, .after-send{
      display: none;
    }
    .important{
      color: $another-red;
    }
  }
  h2{
    @include engineRegular;
    font-size: em(28px);
    color: black;
  }

  input.email{
    margin: 22px 10px 0;
    border: none;
    font-size: em(17px);
    text-align: center;
    padding: 10px;
    border-bottom: $btn-gray solid 1px;
    width: 90%;
    font-family: 'Helvetica';
    color: black;
    outline: none;
    background: none !important;
    &.wrong{
      color: $go-bg;
      border-bottom: $go-bg solid 1px;
    }
  }

  .buttons{
    margin-top: 50px;
    margin-bottom: 5px;
    .send-email-form,
    .cancel-email-form{
      @include engineRegular;
      width: 130px;
      margin: 0 3px;
      @include breakpoint($mobile) {
        width: 120px;
      }
    }
    .send-email-form{
      @include bigBlueButton;
      padding: 16px 0;
    }
    .cancel-email-form{
      @include bigBlueBorderButton
      background-color: white;
      padding: 16px 0;
    }
    .done{
      display: none;
      @include inline-img($tick-white, 36px, 27px, center, center);
      background-color: $another-green;
      width: 68px;
      height: 68px;
      border-radius: 34px;
      margin: 0 auto 9px;
    }
    .email-loader{
      display: none;
      margin: 0 auto 9px;
      width: 68px;
      height: 68px;
      .circular{
        width: 68px;
        height: 68px;
      }
    }

  }
  .mailcheck-container .suggestion {
    @include robotoLight;
    font-size: em(13px);
    padding-top: 10px;
    line-height: 1.3;
    margin-bottom: -26px;
  }
}


