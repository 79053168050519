/*some special styles for main page*/
.first, .second{
  @include engineRegular;
  text-align: center;
  width: 100%;
  float: left;
}

section.first{
  h1{
    font-size: 74px;
    color: $orange;
    margin: 5px 0 0;
    font-weight: normal;
    line-height: 89px;
    @include breakpoint($mobile-tablet) {
      display: none;
    }
  }
}

.play{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 96px;
  height:96px;
  @include inline-img($play, $posLeft: center);
  &.rotate{
    animation: rotating 125ms ease;
  }
}

section.third{
  width: 100%;
  text-align: center;
  position: relative;
  float: left;
  z-index: 3;
  .btn{
    font-size: 22px;
    font-weight: 500;
    padding: 22px 37px;
    @include breakpoint($desktop) {
      margin: 75px 0 0;
    }
  }
}





