section.tasks-main{
  overflow-x: hidden;
  position: relative;
  z-index: 3;
  background-color: $omfg-gray;
  text-align: center;
  width: 100%;
  float: left;
  margin-top: 38px;
  padding-bottom: 32px;
  @include breakpoint($tablet) {
    padding-bottom: 62px;
    margin-top: 31px;
  }
  @include breakpoint($desktop) {
    padding-bottom: 39px;
    margin-top: 81px;
  }
  h2{
    @include engineRegular;
    font-size: 32px;
    color: $green;
    margin: 18px 10px 0;
    z-index: 2;
    position: relative;
    @include breakpoint($tablet) {
      font-size: 48px;
      width: 640px;
      margin: 65px auto 0;
    }
    @include breakpoint($desktop) {
      font-size: 64px;
      margin: 87px auto 0;
      width: 1170px;
    }
    i{
      position: absolute;
      display: block;
      z-index: -1;
      &.pencil, &.candy, &.marker{
        @include breakpoint($mobile) {
          display: none;
        }
      }
      &.pencil{
        background: 0 0 no-repeat;
        @include background-image-retina($path + 'pencil', 'png', 230px, auto);
        width: 230px;
        height: 99px;
        top: -26px;
        left: -97px;
        @include breakpoint($tablet) {
          top: -38px;
          left: -195px;
        }
      }
      &.candy{
        background: 0 0 no-repeat;
        @include background-image-retina($path + 'candy', 'png', 73px, auto);
        width: 73px;
        height: 45px;
        bottom: -65px;
        left: 518px;
        @include breakpoint($tablet) {
          bottom: -50px;
          left: 284px;
        }
      }
      &.marker{
        background: 0 0 no-repeat;
        @include background-image-retina($path + 'marker', 'png', 102px, auto);
        width: 102px;
        height: 107px;
        top: 8px;
        right: -64px;
        @include breakpoint($tablet) {
          top: -9px;
          right: -130px;
        }
      }
    }
  }
  .all-tasks{
    font-size: 22px;
    font-weight: 500;
    color: $btn-blue;
    background: $white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 23px 30px 22px 35px;
    &:hover{
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
    }
  }

}
ul.tasks-list,
ul.another-tasks-list{
  padding: 14px 0 20px;
  overflow: auto;
  @include breakpoint($tablet-desktop) {
    width: 720px;
    text-align: left;
    margin: 0 auto;
    padding: 46px 0 24px;
  }
  @include breakpoint($desktop) {
    width: 1200px;
    padding: 77px 0 40px;
  }

  li{
    background-color: $white;
    width: 88%;
    border-radius: 8px;
    margin: 12px auto;
    position: relative;
    display: inline-block;

    @include breakpoint($tablet) {
      width: 315px;
      margin: 15px 20px;
      float: left;
    }
    @include breakpoint($desktop) {
      width: 365px;
      margin: 20px 15px;
      float: left;
    }
    .task-image{
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      display: block;
      @include breakpoint($desktop) {
        height: 208px;
      }
      @include breakpoint($tablet) {
        height: 180px;
      }
      img{
        width: 100%;
        //border-radius: 8px 8px 0 0;
      }
      &.default{
        @include deault-taks-img (131px)
        min-height: 168px;
      }
      &:hover ~ .task-person a:not(.author){
        color: $red-hover;
      }
    }
    .icon{
      position: absolute;
      top: 14px;
      right: 12px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      &.locked{
        @include inline-img($lock, 36px, 36px, center, center);
        background-color: $default-task-bg;
      }
      img{
        display: block;
        width: 36px;
        margin: 12px 0 0 12px;
      }
    }
    .task-person{
      width: 100%;
      float: left;
      font-size: 14px;
      color: $light-text;
      text-align: left;
      .avatar{
        float: left;
        margin: 15px 10px 10px 10px;
      }
      a{
        &.author{
          display: inline-block;
          font-size: 14px;
          font-weight: 300;
          color: $btn-blue;
          margin: 1px 10px 10px 0;
          &:hover{
            color: $red-hover;
          }
        }
        &.task-name {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          position: relative;
          font-size: 16px;
          color: $dark-gray;
          font-weight: 500;
          margin: 20px 10px 0 0;
          &:hover{
            color: $red-hover;
          }
          &:after{
            display: block;
            width: 59px;
            height: 100%;
            background-image: linear-gradient(to left, #ffffff 0, rgba(255, 255, 255, 0.0) 100%);
            content: '';
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }


  }
}
