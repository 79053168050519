// Colors
$ps-border-radius: 25px !default;

$ps-rail-default-opacity: 0.12 !default;
$ps-rail-container-hover-opacity: 0.12 !default;
$ps-rail-hover-opacity: 0.12 !default;

$ps-bar-bg: transparent !default;
$ps-bar-container-hover-bg: #000 !default;
$ps-bar-hover-bg: #000 !default;
$ps-rail-hover-bg: transparent !default;

// Sizes
$ps-scrollbar-x-rail-bottom: 8px !default;
$ps-scrollbar-x-rail-height: 4px !default;
$ps-scrollbar-x-bottom: 0 !default;
$ps-scrollbar-x-height: 4px !default;

$ps-scrollbar-y-rail-right: 0 !default;
$ps-scrollbar-y-rail-width: 12px !default;
$ps-scrollbar-y-right: 8px !default;
$ps-scrollbar-y-width: 4px !default;
