#smartbanner { position:absolute; left:0; top:-82px; /*border-bottom:1px solid #e8e8e8;*/ width:100%; height:78px; font-family:'Helvetica Neue',sans-serif; background:#f3f3f3; /*box-shadow:0 1px 2px rgba(0,0,0,0.5)*/; z-index:9998; -webkit-font-smoothing:antialiased; overflow:hidden; -webkit-text-size-adjust:none; }
#smartbanner, html.sb-animation {-webkit-transition: all .3s ease;}
#smartbanner .sb-container { margin: 0 auto; }
#smartbanner .sb-close { position:absolute; left:0.4rem; top:2rem; display:block; border:none; width:14px; height:14px; font-family:'ArialRoundedMTBold',Arial; font-size:20px; line-height:15px; text-align:center; color:#535353; background:transparent; text-decoration:none; text-shadow:none; border-radius:0px; box-shadow:none; -webkit-font-smoothing:subpixel-antialiased; }
#smartbanner .sb-close:active { font-size:13px; color:#aaa; }
#smartbanner .sb-icon { border: 1px solid #e8e8e8; position:absolute; left:30px; top:10px; display:block; width:57px; height:57px; background:#fff; background-size:cover; border-radius:10px; /*box-shadow:0 .5px 0.5px rgba(0,0,0,0.5);*/ }
#smartbanner.no-icon .sb-icon { display:none; }
#smartbanner .sb-info { position:absolute; left:98px; top:18px; width:44%; font-size:11px; line-height:1.2em; font-weight:bold; color:#6a6a6a; text-shadow:0 1px 0 rgba(255,255,255,0.8); }
#smartbanner #smartbanner.no-icon .sb-info { left:34px; }
#smartbanner .sb-info strong { display:block; font-size:13px; color:#4d4d4d; line-height: 18px; }
#smartbanner .sb-info > span { display:block; }
#smartbanner .sb-info em { font-style:normal; text-transform:uppercase; }
#smartbanner .sb-button { position:absolute; right:20px; top:30px; padding: 0 10px; min-width: 10%; height:20px; font-size:12px; line-height:20px; text-align:center; font-weight:bold; color:#6a6a6a; background:-webkit-linear-gradient(top, #efefef 0%,#dcdcdc 100%); text-transform:uppercase; text-decoration:none; text-shadow:0 1px 0 rgba(255,255,255,0.8); border-radius:3px; box-shadow:0 1px 0 rgba(255,255,255,0.6),0 1px 0 rgba(255,255,255,0.7) inset; }
#smartbanner .sb-button:active, #smartbanner .sb-button:hover { background:-webkit-linear-gradient(top, #dcdcdc 0%,#efefef 100%); }
#smartbanner .sb-icon.gloss:after { content:''; position:absolute; left:0; top:-1px; border-top:1px solid rgba(255,255,255,0.8); width:100%; height:50%; background:none; border-radius:10px 10px 12px 12px; }
#smartbanner.android { box-shadow: none; }
#smartbanner.android .sb-close { border:0; width:17px; height:17px; line-height:17px; color:#535353;  }
#smartbanner.android .sb-close:active { color:#eee; }
#smartbanner.android .sb-button { min-width: 12%; padding:1px; color:#4285f4; background: none; border-radius: 0; box-shadow: none; min-height:20px}
#smartbanner.android .sb-button span { text-align: center; display: block; padding: 0 10px; background-color: none; text-transform:uppercase; text-shadow:none; box-shadow:none; }
#smartbanner.android .sb-button:active, #smartbanner.android .sb-button:hover { background: none; }
#smartbanner.android .sb-button:active span, #smartbanner.android .sb-button:hover span { background:none; }
#smartbanner.windows .sb-icon { border-radius: 0px; }
