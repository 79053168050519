.social_login_buttons,
.social_share_buttons,
.social_invite_buttons
{
  text-align: center;
  margin-left: 6px;
    @include breakpoint($mobile) {
      margin-left: 3px;
    }
  a{
    display: inline-block;
    width: 68px;
    height: 68px;
    margin-right: 6px;
    border-radius: 34px;
    @include breakpoint($mobile) {
      width: 48px;
      height: 48px;
      margin-right: 3px;
    }
  }
  .facebook:hover {
    background-color: #394f8a;
  }
  .facebook{
    @include inline-img($facebook, 13px, auto, 27px, 22px);
    background-color: #3b5998;
    @include breakpoint($mobile) {
      background-size: 9px auto;
      background-position: 19px 15px;
    }
  }
  .google:hover{
    background-color: #bf442d;
  }
  .google{
    @include inline-img($google, 24px, auto, 23px, 24px);
    background-color: #dd4b39;
    @include breakpoint($mobile) {
      background-size: 17px auto;
      background-position: 16px 17px;
    }
  }
  .odnoklassniki:hover{
    background-color: #cd7600;
  }
  .odnoklassniki{
    @include inline-img($ok, 15px, auto, 26px, 22px);
    background-color: #ed8300;
    @include breakpoint($mobile) {
      background-size: 11px auto;
      background-position: 18px 15px;
    }
  }
  .vkontakte:hover{
    background-color: #4a6994;
  }
  .vkontakte{
    @include inline-img($vk, 25px, auto, 21px, 28px);
    background-color: #4c75a3;
    @include breakpoint($mobile) {
      background-size: 17px auto;
      background-position: 15px 20px;
    }
  }
  .twitter:hover{
    background-color: #4695d2;
  }
  .twitter{
    @include inline-img($twitter, 24px, auto, 22.5px, 25px);
    background-color: #54abee;
    @include breakpoint($mobile) {
      background-size: 17px auto;
      background-position: 16px 18px;
    }
  }
}
