@import 'post_editor';

.child {
  margin: 29px auto 35px;
  width: 88%;
  h2 {
    text-align: center;
    @include breakpoint($tablet-desktop) {
      margin-top: 15px;
    }
  }
  h6 {
    text-align: center;
  }
  @include breakpoint($tablet) {
    width: 92%;
    margin-top: 48px;
    margin-bottom: 0;
  }
  @include breakpoint($desktop) {
    width: 940px;
    margin-top: 64px;
    margin-bottom: 0;
  }
  .avatar {
    margin: 0 auto 15px;
  }
}
section.post {
  max-width: 860px;
  @include breakpoint($desktop) {
    margin-top: 29px;
  }
  .post-media {
    .media-item{
      position: relative;
      .close{
        @include close-btn;
        position: absolute;
        top:-10px;
        right: -10px;
        border-width: 2px;
      }
      .full-photo {
        width: 100%;
        height: auto;
        margin-top: 10px;
      }
    }
    &.tube {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      .play {
        position: absolute;
          left: 28%;
          top: 37%;
          margin: -48px 0 0 -48px;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .text {
    margin: 25px 0 14px;
    @include breakpoint($tablet) {
      margin: 11px 0 0;
    }
    @include breakpoint($desktop) {
      margin: 11px 0 19px;
    }
  }
  .likes {
    margin: 21px 0 20px;
    @include breakpoint($tablet) {
      margin: 30px 0 34px;
    }
    @include breakpoint($desktop) {
      margin: 25px 0 32px;
    }
  }
  #chart {
    margin-top: 15px;
    width: 346px;
    height: 250px;
  }
  .milestone {
    margin-left: 50px;
    p {
      margin-left: 10px;
      position: relative
    }
    .height, .weight {
      position: absolute;
      left: -54px;
      top: 20px;
    }
    .up {
      padding: 0;
      margin-top: 5px;
    }
  }
}

/*popup menu for control*/
.edit {
  float: right;
  position: relative;
  .show-edit-menu {
    display: inline-block;
    width: 24px;
    height: 30px;
    padding-top: 6px;
    float: right;
    i {
      width: inline-img-width($actions);;
      height: inline-img-height($actions);
      @include inline-img($actions);
    }
    &:hover i {
      @include inline-img($actions-red);
    }
  }
  .show-make-public {
    display: inline-block;
    width: 24px;
    height: 30px;
    padding-top: 6px;
    margin-right:10px;
    float: right;
    i {
      width: inline-img-width($actions);
      height: inline-img-height($actions);
      @include inline-img($share);
    }
    &:hover i {
      @include inline-img($share-red);
    }
  }
  .edit-menu {
    display: none;
    position: absolute;
    right: -24px;
    top: 30px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
    padding: 14px 20px 11px 27px;
    z-index: 5;
    @include triangle-up ($white)
    &::after, &::before {
      left: auto;
      right: 31px;
    }
    li {
      padding: 0;
      float: left;
      width: 100%;
      a {
        padding: 5px 0 4px 37px;
        float: left;
        white-space: nowrap;
        line-height: 24px;
        &.remove {
          @include inline-img($trash, $posTop: 2px);
          &:hover {
            @include inline-img($trash-red, $posTop: 2px);
          }
        }
        &.edit-post, &.edit-result-profile {
          @include inline-img($edit, $posTop: 4px);
          &:hover {
            @include inline-img($edit-red, $posTop: 4px);
          }
        }
        &.atention {
          @include inline-img($atention, $posTop: 2px);
          &:hover {
            @include inline-img($atention-red, $posTop: 2px);
          }
        }
        &.unpublish {
          @include inline-img($hide, $posTop: 4px);
          &:hover {
            @include inline-img($hide-red, $posTop: 4px);
          }
        }
        &.download {
          @include inline-img($download, $posTop: 2px);
          &:hover {
            @include inline-img($download-red, $posTop: 2px);
          }
        }
        &.hide{
          display: none;
        }
      }
    }
  }
}
