.user-social{
  &.vkontakte {
    @include inline-img($vk, 15px, 15px, center, center);
    background-color: $vk-bg;
  }
  &.facebook {
    @include inline-img($facebook, 9px, 9px, center, center);
    background-color: $fb-bg;
  }
  &.odnoklassniki {
    @include inline-img($ok, 9px, 9px, center, center);
    background-color: $ok-bg;
  }
  &.google {
    @include inline-img($google, 15px, 15px, center, center);
    background-color: $go-bg;
  }
  &.twitter {
    @include inline-img($twitter, 15px, 15px, center, center);
    background-color: $tw-bg;
  }
}
